import * as React from 'react';
import './scss/index.module.scss';

export interface ResponsiveModalContentProps {
    // Header content
    headerContent?: React.ReactNode;
    // Footer content
    footerContent?: React.ReactNode;
    // Whether or not to render the close button
    notDismissable?: boolean;

    // Event handlers
    handleClickModalClose?: () => void;
    handleClickModalBackground?: () => void;
}

export class ResponsiveModalContent extends React.Component<ResponsiveModalContentProps, {}> {
    static defaultProps: Partial<ResponsiveModalContentProps> = {
        handleClickModalBackground: () => {},
        handleClickModalClose: () => {},
    };

    constructor(props: ResponsiveModalContentProps) {
        super(props);
    }

    /**
     * Render the modal content the standard way
     */
    render() {
        const props = this.props;

        return (
            <div style={{ width: '100%', height: '100%' }}>
                {/* Header container + close button */}
                {!!props.headerContent && (
                    <header className='ResponsiveModal--header nx-modal__header'>
                        <div className='ResponsiveModal--header-content'>{props.headerContent}</div>
                        {!props.notDismissable && (
                            <div className='nx-modal__close'>
                                <button
                                    className='ResponsiveModal--close-button nx-modal__close-button'
                                    type='button'
                                    aria-label='Close form'
                                    onClick={props.handleClickModalClose}
                                >
                                    <span className='icon icon-hb-nx-close' />
                                </button>
                            </div>
                        )}
                    </header>
                )}

                {/* Modal body */}
                <div className='ResponsiveModal--body nx-modal__body'>
                    <div className='ResponsiveModal--body-content nx-container'>{props.children}</div>
                </div>

                {/* Modal footer */}
                {!!props.footerContent && (
                    <footer className='ResponsiveModal--footer nx-modal__footer'>
                        <div className='ResponsiveModal--footer-content'>{props.footerContent}</div>
                    </footer>
                )}
            </div>
        );
    }
}
