import * as React from 'react';

import styles from './LocationsListing.module.scss';
import { FeatureFlagContext } from '../../providers/flag-context';

const CIMarketAndWaffleFlagMappingDict = {
    Anguilla: 'anguilla_show_srps_on_header',
    Aruba: 'aruba_show_srps_on_header',
    Bahamas: 'bahamas_show_srps_on_header',
    Barbados: 'barbados_show_srps_on_header',
    Bermuda: 'bermuda_show_srps_on_header',
    'Cayman Islands': 'cayman_islands_show_srps_on_header',
    Curacao: 'curacao_show_srps_on_header',
    'Dominican Republic': 'dominican_republic_show_srps_on_header',
    Haiti: 'haiti_show_srps_on_header',
    Jamaica: 'jamaica_show_srps_on_header',
    'Puerto Rico': 'puerto_rico_show_srps_on_header',
    'Saint Kitts and Nevis': 'saint_kitts_and_nevis_show_srps_on_header',
    'Saint Lucia': 'saint_lucia_show_srps_on_header',
    'Saint Martin': 'saint_martin_show_srps_on_header',
    'Saint Vincent and The Grenadines': 'saint_vincent_and_the_grenadines_show_srps_on_header',
    'Trinidad and Tobago': 'trinidad_and_tobago_show_srps_on_header',
    'US Virgin Islands': 'us_virgin_islands_show_srps_on_header',
};

interface Props {
    onItemClick: (content: string) => void;
}
interface TrackedLinkProps {
    href?: string;
    style?: Object;
    onItemClick?: (content: string) => void;
    className?: string | undefined;
    children?: {};
}

const LocationsListing = (props: Props) => {
    const { flagEnabled } = React.useContext(FeatureFlagContext);

    const CIMarketWithFlagTurnedON = [];
    for (let market in CIMarketAndWaffleFlagMappingDict) {
        if (flagEnabled(CIMarketAndWaffleFlagMappingDict[market])) {
            CIMarketWithFlagTurnedON.push(market);
        }
    }

    const TrackedLink = (props: TrackedLinkProps) => {
        return (
            <a
                href={props.href}
                style={props.style}
                className={props.className}
                onClick={() => {
                    props.onItemClick!(props.children as string);
                }}
            >
                {props.children}
            </a>
        );
    };

    return (
        <div className={styles.className}>
            <table className={styles.locationsTable}>
                <tbody>
                    <tr>
                        <td>
                            <ul className={styles.list}>
                                <li>
                                    <TrackedLink href='/wedding-venues/alabama/'>Alabama »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/alaska/'>Alaska »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/arizona/'>Arizona »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/arkansas/'>Arkansas »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/northern-california/'>California (Northern) »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/southern-california/'>California (Southern) »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/colorado/'>Colorado »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/connecticut/'>Connecticut »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/delaware/'>Delaware »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/washington-dc/'>Washington DC »</TrackedLink>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul className={styles.list}>
                                <li>
                                    <TrackedLink href='/wedding-venues/florida/'>Florida »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/georgia/'>Georgia »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/hawaii/'>Hawaii »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/idaho/'>Idaho »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/illinois/'>Illinois »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/indiana/'>Indiana »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/iowa/'>Iowa »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/kansas/'>Kansas »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/kentucky/'>Kentucky »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/louisiana/'>Louisiana »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/maine/'>Maine »</TrackedLink>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul className={styles.list}>
                                <li>
                                    <TrackedLink href='/wedding-venues/maryland/'>Maryland »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/massachusetts/'>Massachusetts »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/michigan/'>Michigan »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/minnesota/'>Minnesota »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/mississippi/'>Mississippi »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/missouri/'>Missouri »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/montana/'>Montana »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/nebraska/'>Nebraska »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/nevada/'>Nevada »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/new-hampshire/'>New Hampshire »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/new-jersey/'>New Jersey »</TrackedLink>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul className={styles.list}>
                                <li>
                                    <TrackedLink href='/wedding-venues/new-mexico/'>New Mexico »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/new-york/'>New York »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/north-carolina/'>North Carolina »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/north-dakota/'>North Dakota »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/ohio/'>Ohio »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/oklahoma/'>Oklahoma »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/oregon/'>Oregon »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/pennsylvania/'>Pennsylvania »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/rhode-island/'>Rhode Island »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/south-carolina/'>South Carolina »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/south-dakota/'>South Dakota »</TrackedLink>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul className={styles.list}>
                                <li>
                                    <TrackedLink href='/wedding-venues/tennessee/'>Tennessee »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/texas/'>Texas »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/utah/'>Utah »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/vermont/'>Vermont »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/virginia/'>Virginia »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/washington/'>Washington »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/west-virginia/'>West Virginia »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/wisconsin/'>Wisconsin »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/wyoming/'>Wyoming »</TrackedLink>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul className={styles.list}>
                                <li>
                                    <TrackedLink href='/wedding-venues/mexico/'>Mexico »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/costa-rica/'>Costa Rica »</TrackedLink>
                                </li>
                                <li>
                                    <TrackedLink href='/wedding-venues/caribbean-islands/' style={{ 'text-decoration': 'underline' }}>
                                        Caribbean Islands »
                                    </TrackedLink>
                                </li>
                                {CIMarketWithFlagTurnedON.slice(0, 8).map((market) => {
                                    return (
                                        <li>
                                            <TrackedLink href={`/wedding-venues/${market.toLowerCase().replace(/\s+/g, '-')}/`}> {market} »</TrackedLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </td>
                        <td>
                            <ul className={styles.list}>
                                {CIMarketWithFlagTurnedON.slice(8).map((market) => {
                                    return (
                                        <li>
                                            <TrackedLink href={`/wedding-venues/${market.toLowerCase().replace(/\s+/g, '-')}/`}> {market} »</TrackedLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <div className={styles.textCenter}>
                                <TrackedLink href='/locations/' className={styles.link}>
                                    See all regions
                                </TrackedLink>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default LocationsListing;
