import React from 'react';

const WalkingAwayIcon = () => {
    return (
        <svg width='375' height='375' viewBox='0 0 375 375' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_1537_122797)'>
                <g opacity='0.8'>
                    <path
                        opacity='0.8'
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M120.868 34.3379V340.657L235.438 302.132V47.0845L120.868 34.3379Z'
                        fill='#FFEBF6'
                    />
                    <path
                        opacity='0.8'
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M120.868 340.658L235.438 302.132L120.868 274.108V340.658Z'
                        fill='#D5B4C6'
                    />
                    <path
                        opacity='0.8'
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M160.576 49.4496C167.175 57.896 178.656 60.3437 189.038 63.0157C201.435 66.2075 214.733 71.4905 220.441 82.9494C224.65 91.4002 224.078 102.15 230.286 109.261C231.755 110.944 233.522 112.29 235.438 113.429V47.0839L154.173 38.043C156.283 41.8713 157.886 46.0082 160.576 49.4496Z'
                        fill='#E27FB8'
                    />
                </g>
                <mask id='mask0_1537_122797' maskUnits='userSpaceOnUse' x='120' y='52' width='155' height='281'>
                    <path d='M120.868 332.325L274.5 298.44V60.6897L120.868 52.3564V332.325Z' fill='white' />
                </mask>
                <g mask='url(#mask0_1537_122797)'>
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M152.414 189.791C147.84 194.822 144.527 216.824 143.775 223.582C140.412 253.754 127.372 313.093 127.372 313.093C127.372 313.093 129.613 317.65 133.328 317.555C146.332 299.526 170.342 252.905 176.46 222.326C179.996 204.657 176.086 194.826 171.973 177.282L152.414 189.791Z'
                        fill='#720F47'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M134.785 314.75C135.417 312.086 116.996 306.386 115.71 309.122C114.422 311.859 128.581 322.828 131.246 322.276C133.912 321.725 134.208 317.186 134.785 314.75Z'
                        fill='#720F47'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M240.597 283.692L242.061 278.438C242.061 278.438 219.578 266.053 207.501 249.149C196.972 234.41 195.181 216.872 193.408 209.693C191.008 199.976 186.573 191.359 181.797 188.871L147.51 201.971C154.266 207.81 155.06 219.045 160.121 228.91C165.809 239.997 174.709 252.709 183.172 260.094C211.953 285.209 240.597 283.692 240.597 283.692Z'
                        fill='#881155'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M234.016 279.739C234.016 279.739 237.053 276.333 241.655 277.985C243.985 279.047 242.483 285.866 243.258 291.262C243.517 293.062 244.949 295.897 243.855 296.692C235.689 295.359 234.016 279.739 234.016 279.739Z'
                        fill='#881155'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M265.489 99.0803C263.333 97.3571 262.055 101.297 260.135 101.699C260.997 98.8176 265.53 92.7099 263.626 91.3554C261.21 89.6387 258.749 97.6939 258.221 97.9443C258.056 94.1138 260.381 86.2183 257.38 85.9236C254.987 85.6891 255.704 94.3838 254.528 96.9506C254.271 96.6951 253.731 86.5595 251.123 86.8636C250.127 86.9798 250.021 88.6617 250.168 89.601C250.615 92.4777 250.866 94.958 250.881 97.8681C250.886 99.0353 251.695 101.516 249.642 101.111C249.272 101.038 248.837 100.364 247.45 99.1798C246.065 97.9959 243.412 97.27 243.137 98.2122C242.573 100.147 244.142 100.331 245.314 101.72C247.005 103.723 248.176 104.945 249.547 105.509C250.966 106.093 251.462 107.771 251.424 109.262L255.185 109.619C256.687 108.542 258.139 107.278 259.043 105.806C261.127 105.716 266.622 100.751 265.489 99.0803Z'
                        fill='#EDBF87'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M255.991 109.256C249.617 136.49 224.646 150.875 196.592 149.308C188.67 167.319 191.537 193.643 191.537 193.643C191.537 193.643 169.48 212.259 146.59 201.88C146.633 198.1 151.504 172.587 158.697 155.395C145.678 167.4 128.906 173.192 104.005 180.094C103 178.782 102.627 175.407 102.796 173.564C110.829 171.251 136.754 156.261 153.562 141.523C160.664 135.295 168.14 129.63 177.263 127.876C180.608 127.232 185.814 128.337 193.936 129.25C204.643 130.455 212.475 134.27 227.887 129.189C237.811 125.917 246.081 118.408 251.171 108.104L255.991 109.256Z'
                        fill='#E1168A'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M210.091 101.717C211.233 103.334 209.246 107.064 207.708 109.813C206.812 111.412 205.868 113.013 204.571 114.308C201.891 116.983 198.052 118.059 194.767 119.941C197.073 117.588 198.029 115.078 199.829 112.32C201.032 110.478 201.376 107.295 200.838 105.163L210.091 101.717Z'
                        fill='#9981DE'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M180.865 119.835C181.813 119.121 193.082 111.338 200.076 113.369C193.165 122.322 191.897 131.186 190.189 134.488C189.905 135.034 182.894 141.839 177.096 141.824C172.321 141.812 172.889 137.609 172.697 133.971C172.5 130.203 177.579 122.314 180.865 119.835Z'
                        fill='#EDBF87'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M177.88 124.956C180.754 125.6 183.763 125.063 186.533 124.082C190.892 122.537 194.326 118.688 197.453 115.231C199.877 112.549 202.318 110.566 203.586 107.147C211.449 85.9442 193.637 77.762 179.609 89.9918C172.775 95.9499 160.126 120.985 177.88 124.956Z'
                        fill='#EDBF87'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M205.956 111.558C204.679 113.602 200.647 114.944 199.464 113.681C198.69 112.856 198.354 112.606 198.886 111.351C199.17 110.683 201.638 107.485 202.246 107.177C203.629 106.478 205.054 106.37 205.877 107.248C207.156 108.613 207.006 109.876 205.956 111.558Z'
                        fill='#EDBF87'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M202.207 84.8526C201.597 82.0659 199.381 79.6225 196.844 78.3189C194.308 77.0152 191.526 76.2668 188.898 75.1583C188.107 74.8244 187.278 74.4099 186.871 73.6543C186.465 72.8979 186.761 71.7292 187.603 71.5645C186.122 70.983 184.342 71.2327 183.079 72.2011C181.815 73.1701 181.111 74.823 181.289 76.4047C181.408 77.479 181.896 78.4727 182.38 79.4389C183.5 81.679 184.667 83.9539 186.454 85.7084C187.79 87.0194 189.417 87.9862 191.027 88.9401C193.412 90.3519 195.177 91.3631 196.509 91.4647C200.69 91.7863 203.271 89.7182 202.207 84.8526Z'
                        fill='#7050CB'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M196.087 82.119C191.275 74.0841 181.55 75.9126 179 73.6319C176.85 71.7083 178.168 67.0996 180.697 65.8242C165.534 66.9552 166.656 77.3782 166.779 79.1762C167.041 83.0314 171.755 86.8242 175.348 88.244C178.942 89.6639 183.292 90.0058 187.147 90.2664C190.529 90.4943 197.445 93.7746 197.445 93.7746C197.445 93.7746 199.09 87.1334 196.087 82.119Z'
                        fill='#815FE3'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M195.011 92.9148C192.53 91.5248 189.471 91.5168 186.712 92.2114C183.955 92.9061 181.409 94.241 178.836 95.4525C176.098 96.741 173.268 97.909 170.284 98.4098C167.3 98.9114 164.125 98.7038 161.422 97.3457C156.938 95.094 154.38 89.5649 155.568 84.6898C155.767 83.8775 156.067 83.0674 156.622 82.441C157.045 81.9626 157.596 81.6164 158.156 81.3086C159.547 80.5435 161.049 79.9809 162.6 79.6412C161.994 80.5653 162.227 82.041 163.051 82.7785C163.874 83.5167 165.244 83.5429 166.096 82.8373C166.45 82.5448 166.708 82.1564 166.989 81.7935C168.607 79.708 171.092 78.4043 173.676 77.8701C176.26 77.3373 178.947 77.5216 181.541 78.0065C185.086 78.67 188.499 80.1762 191.036 82.7386C194.391 86.127 195.011 92.9148 195.011 92.9148Z'
                        fill='#9981DE'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M203.227 86.3474C206.936 86.1702 209.524 90.6562 210.35 95.3658C211.168 100.025 210.942 105.115 209.037 106.319C202.724 110.306 203.074 105.002 196.838 112.995C196.231 112.359 195.307 111.536 194.585 111.112C195.371 109.182 195.757 108.202 196.647 106.317C197.779 103.92 197.965 101.125 197.293 98.5721C196.73 96.4343 194.998 94.6254 194.528 92.6074C195.677 88.4619 199.385 86.5303 203.227 86.3474Z'
                        fill='#9981DE'
                    />
                    <path
                        d='M175.138 112.324C174.191 111.913 173.135 110.926 173.19 109.665C173.244 108.453 176.445 107.036 177.486 104.045C177.912 102.823 177.347 100.625 176.278 99.6692C175.181 98.6878 173.597 98.6 171.526 99.4059'
                        stroke='#A66542'
                        stroke-width='1.61934'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M188.85 105.996C188.661 106.955 187.902 107.614 187.153 107.465C186.405 107.318 185.952 106.422 186.141 105.463C186.329 104.503 187.089 103.845 187.837 103.993C188.586 104.14 189.04 105.038 188.85 105.996Z'
                        fill='#A66542'
                    />
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M175.932 103.268C175.743 104.227 174.983 104.885 174.235 104.737C173.486 104.59 173.033 103.693 173.222 102.734C173.411 101.775 174.17 101.117 174.919 101.264C175.668 101.412 176.121 102.31 175.932 103.268Z'
                        fill='#A66542'
                    />
                    <path
                        d='M183.122 101.394C183.122 101.394 186.797 99.1133 188.887 102.407'
                        stroke='#A66542'
                        stroke-width='1.61934'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        d='M184.06 115.952C184.06 115.952 182.811 118.722 179.578 117.876'
                        stroke='#A66542'
                        stroke-width='1.61934'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        d='M199.17 114.012C199.17 114.012 193.657 122.248 186.069 123.61'
                        stroke='#A66542'
                        stroke-width='1.61934'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        d='M162.723 144.975C162.723 144.975 157.265 157.069 156.381 162.231'
                        stroke='#720F47'
                        stroke-width='1.61934'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </g>
            </g>
            <defs>
                <clipPath id='clip0_1537_122797'>
                    <rect width='375' height='375' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
}

export default WalkingAwayIcon;