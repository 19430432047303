import * as React from 'react';
import Slider, { SliderImage } from './Slider';
import { Settings } from 'react-slick';

export interface SliderContainerProps {
    /* Images and videos to be displayed */
    images?: { src: string; credit?: string }[];
    videos?: string[];

    /* Venue properties */
    venueId?: number;
    venueName: string;
    venueRegion?: string;
    isPricedVenue?: boolean;
    renderCtaSlide?: boolean;
    estimateComplete?: boolean;
    slickSettingsOverride?: Settings;
    showCounter?: boolean;
    forceWebStyle?: boolean;
    itemProp?: boolean;

    /* Event handlers for interactions */
    onReportClick?: (currentImage: SliderImage | null) => void;
    onActiveImageChange?: (index: number) => void;
    onContactClick?: () => void;

    /*
    Indicates whether or not we want to only preload a subset of the images in the carousel (default behavior
    is to load all of them at once). The number of images initially loaded and how many to buffer as the user
    scrolls is determined by the startImageLoad and stepImageLoad props
    */
    profileImagesLazyLoad?: boolean;
    /*
    Indicates how many starting from index 0 to load initially. The image at index -1 is always loaded initially
    and not included in this count (e.g. startImageLoad = 3 means images at index -1, 0, 1, 2 are loaded)
    */
    startImageLoad?: number;
    /*
    Indicates the size of the buffer to maintain as the user scrolls. On first scroll, the buffer is enforced
    i.e. if startImageLoad = 1 and stepImageLoad = 2, initially images 0 and 1 are loaded. When the user first
    scrolls to the right, images 0, 1, 2, and 3 would be loaded to ensure a buffer of 2 images is establised.
    Subsequent scrolls to the right will load 1 more image, this buffer applies to the left scroll direction
    as well
    */
    stepImageLoad?: number;
    /* Indicate that we don't want any images to be loaded yet. This is useful for defering loading of images */
    dontLoadImagesYet?: boolean;
    hidePhotoCredits?: boolean;
    settings?: object;
    venueProfileVersionThree?: boolean;

    classes?: {
        sliderContainerWrapper?: string;
        sliderImageWrapper?: string;
        slideContainerWrapper?: string;
        slideImageWrapper?: string;
        imageWrapper?: string;
    };
}

const defaultImages = new Array<SliderImage>();
const defaultVideos = new Array<string>();

export const SliderContainer: React.FC<SliderContainerProps> = ({
    images = defaultImages,
    videos = defaultVideos,
    onReportClick = (...args: any[]) => {},
    onContactClick = (...args: any[]) => {},
    onActiveImageChange = (...args: any[]) => {},
    estimateComplete = false,
    isPricedVenue = false,
    venueId,
    venueName,
    venueRegion,
    settings,
    profileImagesLazyLoad,
    startImageLoad,
    stepImageLoad,
    renderCtaSlide,
    dontLoadImagesYet,
    slickSettingsOverride,
    forceWebStyle,
    itemProp,
    showCounter,
    hidePhotoCredits,
    classes,
    venueProfileVersionThree,
}) => {
    return (
        <Slider
            videos={videos}
            venueId={venueId}
            venueName={venueName}
            venueRegion={venueRegion}
            isPriced={isPricedVenue}
            images={images}
            onActiveImageChange={onActiveImageChange}
            onReportClick={onReportClick}
            estimateComplete={estimateComplete}
            onContactClick={onContactClick}
            settings={settings}
            profileImagesLazyLoad={profileImagesLazyLoad}
            startImageLoad={startImageLoad}
            stepImageLoad={stepImageLoad}
            renderCtaSlide={renderCtaSlide}
            dontLoadImagesYet={dontLoadImagesYet}
            slickSettingsOverride={slickSettingsOverride}
            forceWebStyle={forceWebStyle}
            itemProp={itemProp}
            showCounter={showCounter}
            hidePhotoCredits={hidePhotoCredits}
            classes={classes}
            venueProfileVersionThree={venueProfileVersionThree}
        />
    );
};
