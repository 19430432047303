import * as React from 'react';
import cn from 'classnames';
import Button from '@cvent/carina/components/Button';
import XIcon from '@cvent/carina/components/Icon/X';
import { ShoppingCartContext } from './ShoppingCartContext';
import { CartItem as CartItemComponent } from './CartItem';
import { CartItem } from './service';
import { useCallback } from 'react';
import { logEvent, AnalyticsEvent } from '@weddingspot/ws-analytics';

import styles from './ShoppingCart.module.scss';

interface ShoppingCartProps {
    // Maximum number of items allowed in cart
    itemsMax: number;

    // Title to show at the top of the cart
    title: string;

    // Subtitle to show under the cart title
    subtitle: string;

    // Title to show above the recommendations
    recommendationsTitle?: string;

    // Help text to show in the "add more..." help box
    addHelpText: (remaining: number) => string;

    // The text for the "add more..." help box link
    addCTAText: string;

    // Event handler for the "add more..." link; Promise should resolve to true to close the modal after
    // handling, and false to leave it open
    onClickAddCTA: () => Promise<boolean>;

    // The text to show in the submit button
    submitCTAText: string;

    // Event handler for the submit CTA; Promise should return true to close the modal after handling,
    // and false to leave it open
    onClickSubmitCTA: () => Promise<boolean>;

    // Text to show below the submit button
    submitCTASubtitle: string;

    onRemoveItem?: (id: string | number) => void;
}

export const ShoppingCart = (props: ShoppingCartProps) => {
    const {
        itemsMax,
        title,
        subtitle,
        recommendationsTitle,
        addHelpText,
        addCTAText,
        onClickAddCTA,
        submitCTAText,
        onClickSubmitCTA,
        submitCTASubtitle,
        onRemoveItem,
    } = props;

    const { cartOpen, setCartOpen, cartReady, items, removeItem } = React.useContext(ShoppingCartContext);

    const canAddMore = items.length < itemsMax;
    const recommendedItems = items.filter((i) => i.isRecommendation);

    const removeHandler = (id: string | number) => {
        removeItem(id);
        if (onRemoveItem) {
            onRemoveItem(id);
        }
    };

    const makePoweredBySubtitle = useCallback(
        (isDestination?: boolean) => (isDestination == false ? null : <b>by Destination Weddings</b>),
        []
    );

    return (
        <div className={cn(styles.container, { [styles.cartOpen]: cartOpen })}>
            {!cartReady ? (
                <div className={styles.cartLoadingContainer}>Loading...</div>
            ) : (
                <>
                    <div className={styles.header}>
                        <div>
                            <div className={styles.title}>{title}</div>
                            <div className={styles.subtitle}>{subtitle}</div>
                        </div>
                        <div className={styles.closeButton} onClick={() => setCartOpen(false)}>
                            <XIcon color='#69717A' />
                        </div>
                        <Button text='Close' onClick={() => setCartOpen(false)} size={'s'} appearance={'lined'} />
                    </div>
                    <div className={styles.listWrapper}>
                        <div className={styles.mainItemsContainer}>
                            {items
                                .filter((i) => !i.isRecommendation)
                                .map((i: CartItem) => (
                                    <CartItemComponent
                                        key={i.id}
                                        id={i.id}
                                        image={i.imageUrl}
                                        title={i.name}
                                        subtitle={i.location}
                                        rating={i.rating}
                                        onClickRemove={removeHandler}
                                        additionalSubtitle={makePoweredBySubtitle(i.isDestination)}
                                    />
                                ))}
                        </div>

                        {recommendedItems.length > 0 && (
                            <div className={styles.recommendationsContainer}>
                                {recommendationsTitle && <div className={styles.recommendationsTitle}>{recommendationsTitle}</div>}
                                <div className={styles.recommendedVenues}>
                                    {recommendedItems.map((i: CartItem) => (
                                        <CartItemComponent
                                            key={i.id}
                                            id={i.id}
                                            image={i.imageUrl}
                                            title={i.name}
                                            subtitle={i.location}
                                            rating={i.rating}
                                            onClickRemove={(id: string | number) => removeItem(id)}
                                            additionalSubtitle={makePoweredBySubtitle(i.isDestination)}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}

                        {canAddMore && (
                            <div className={styles.addMoreCTAContainer}>
                                <div className={styles.addMoreHelpText}>{addHelpText(itemsMax - items.length)}</div>
                                <div
                                    className={styles.addMoreCTAText}
                                    onClick={() =>
                                        onClickAddCTA().then((open) => {
                                            setCartOpen(open);
                                        })
                                    }
                                >
                                    {addCTAText}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={styles.submitButtonContainer}>
                        <Button
                            text={submitCTAText}
                            disabled={items.length < 1}
                            appearance={'primary'}
                            onClick={() =>
                                onClickSubmitCTA().then((open) => {
                                    const location = window?.location?.pathname;

                                    logEvent(AnalyticsEvent.EVTNM_CLICKED_SHOPPING_CARD_SUBMIT_MESSAGE_VENUE_CTA, {
                                        source: location.startsWith('/pricing/')
                                            ? 'spot estimate page'
                                            : location.startsWith('/venue/')
                                            ? 'venue profile page'
                                            : location,
                                    });
                                    setCartOpen(open);
                                })
                            }
                        />
                        <div className={styles.submitCTASubtitle}>{submitCTASubtitle}</div>
                    </div>
                </>
            )}
        </div>
    );
};
