import './styles.scss';

import * as React from 'react';
import { default as LoadingSpinner, SpinnerProps } from '../LoadingSpinner';
import classnames from 'classnames';

interface SpinnerOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
    isSpinning: boolean;
    noOpacity?: boolean;
    spinnerProps?: Partial<SpinnerProps>;
    overlayClassName?: string;
}

/**
 * Spinner overlay
 */
export default class SpinnerOverlay extends React.Component<SpinnerOverlayProps> {
    render() {
        const { isSpinning, noOpacity, spinnerProps, ...divProps } = this.props;
        return (
            <div
                {...divProps}
                className={classnames('SpinnerOverlay', { SpinnerOverlay__spinning: isSpinning }, this.props.overlayClassName)}
            >
                <div className='SpinnerOverlay--content'>
                    {this.props.children}
                    {isSpinning && (
                        <div
                            className={classnames('SpinnerOverlay--spinner-container', {
                                'SpinnerOverlay--spinner-container__no-opacity': this.props.noOpacity,
                            })}
                        >
                            <LoadingSpinner {...spinnerProps} className='SpinnerOverlay--spinner' />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
