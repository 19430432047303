// @ts-nocheck
import * as React from 'react';
import cn from 'classnames';

import { CSNVenueProfile } from '@weddingspot/ws-api-client';
import { MapPinIcon, CheckIcon } from '@cvent/carina/components/Icon';
import { RatingCSN } from '../csn/index';
import { ShoppingCartContext } from '../shopping-cart/ShoppingCartContext';
import { CartItem } from '../shopping-cart/service';

import { getHotelRatingFromCsnFormat, parseQuickViewData, QuickViewModeData } from './utils';
import { getDistanceFromCoordinates } from '../../utils/functions';
import Slider from '../slider';
import { useState } from 'react';
import { AnalyticsEvent, logEvent } from '@weddingspot/ws-analytics';
import { LoadingSpinner } from '@cvent/carina/components/LoadingSpinner';

const TextTruncate = require('react-text-truncate');
const _styles = require('./HotelCard.module.scss');

export interface HotelCardProps {
    id: string;
    profileData: CSNVenueProfile;
    name: string;
    hotelType: string;
    state?: string;
    city: string;
    rating?: string;
    guestRooms: number;
    imageUrl?: {
        small: string;
        medium: string;
        large: string;
    };
    distance?: number;
    eventCoordinates?: [number, number];
    hotelCoordinates?: [number, number];
    onRefSet?: (element: HTMLDivElement | null) => void;
    onQuickView: (height: number) => void;
    focused: boolean;

    // External styling props
    classes?: {
        wrapper?: string;
        title?: string;
    };

    fromVenuePageProps?: {
        onClickQuickView: () => void;
        onSubmit: () => void;
    };
}

const propsToCartItem = (props: HotelCardProps): CartItem => {
    const ratingProps = getHotelRatingFromCsnFormat(props.rating);
    return {
        id: props.id,
        name: props.name,
        location: `${props.city}, ${props.state}`,
        imageUrl: props.imageUrl?.small,
        rating: !!ratingProps.value && !!ratingProps.type ? (ratingProps as { value: number; type: 'DIAMONDS' | 'STARS' }) : undefined,
        lastUpdated: new Date().getTime(),
    };
};

const InfoDescriptionComponent = ({ description }: { description: string }) => {
    const [isShown, setShown] = useState(description.length <= 150);

    React.useEffect(() => {
        setShown(description.length <= 150);
    }, [description.length]);

    return (
        <div className={_styles.infoDescription}>
            {isShown ? description : description.substring(0, 150)}

            {!isShown && (
                <button className={_styles.secondaryButton} onClick={() => setShown(true)}>
                    Show more
                </button>
            )}
        </div>
    );
};

const hotelsTypesMap = {
    HOTEL: 'Hotel',
    RESORT: 'Resort',
    LUXURY_HOTEL: 'Luxury',
    BOUTIQUE_HOTEL: 'Boutique',
};

export const HotelCard = React.memo((props: HotelCardProps) => {
    const {
        id,
        profileData,
        imageUrl,
        name,
        hotelType: hotelTypeRaw,
        state,
        city,
        guestRooms,
        rating: ratingRaw,
        eventCoordinates,
        hotelCoordinates,
        onRefSet,
        onQuickView,
        focused,
        fromVenuePageProps,
    } = props;

    const containerRef = React.useRef<HTMLDivElement | null>(null);

    const classes = props.classes || {};

    const { items, addItem, removeItem } = React.useContext(ShoppingCartContext);
    const selected = items.findIndex((item: CartItem) => item.id === id) !== -1;

    const [quickViewMode, setQuickViewMode] = React.useState(false);
    const [quickViewModeData, setQuickViewModeData] = React.useState<QuickViewModeData>();
    const [quickViewModeDataLoading, setQuickViewModeDataLoading] = React.useState(true);

    React.useEffect(() => {
        if (profileData) {
            setQuickViewModeData(parseQuickViewData(profileData));
            setQuickViewModeDataLoading(false);
        }
    }, [profileData]);
    const onQuickViewClick = () => {
        if (fromVenuePageProps) {
            if (!quickViewMode) {
                fromVenuePageProps.onClickQuickView();
            }
        } else {
            if (quickViewMode) {
                // Was selected and it being deselected
                logEvent(AnalyticsEvent.EVTNM_CLICKED_HOTEL_CARD_CLOSEQUICKVIEW, { source: 'hotel blocks SRP' });
            } else {
                // Was not selected and is being selected
                logEvent(AnalyticsEvent.EVTNM_CLICKED_HOTEL_CARD_QUICKVIEW, { source: 'hotel blocks SRP' });
            }
        }

        setQuickViewMode(!quickViewMode);
    };

    React.useEffect(() => {
        if (!(focused && containerRef.current && window.innerWidth < 750)) {
            return;
        }
        onQuickView(quickViewMode ? containerRef.current.getBoundingClientRect().height : 0);
    }, [focused && containerRef.current, quickViewMode, quickViewModeDataLoading]);

    React.useEffect(() => {
        if (window.innerWidth < 750 && focused) {
            setQuickViewMode(false);
        }
    }, [focused && containerRef.current]);

    const { value: ratingValue, type: ratingType } = getHotelRatingFromCsnFormat(ratingRaw);

    const hotelType = hotelTypeRaw && hotelsTypesMap[hotelTypeRaw];

    const distance =
        eventCoordinates &&
        hotelCoordinates &&
        getDistanceFromCoordinates(hotelCoordinates[0], hotelCoordinates[1], eventCoordinates[0], eventCoordinates[1]).toFixed(2);

    const renderImageSection = () => {
        if (quickViewMode && quickViewModeData?.images?.length) {
            return (
                <div className={_styles.imageSectionQM}>
                    <Slider
                        venueName={name}
                        images={quickViewModeData.images.filter((x) => x).map((x) => ({ src: x.medium, credit: '' }))}
                        hidePhotoCredits={true}
                        classes={{
                            sliderContainerWrapper: _styles.sliderContainerWrapper,
                            sliderImageWrapper: _styles.sliderImageWrapper,
                            slideContainerWrapper: _styles.slideContainerWrapper,
                            slideImageWrapper: _styles.slideContainerWrapper,
                            imageWrapper: _styles.imageWrapper,
                        }}
                    />
                </div>
            );
        } else if (quickViewMode) {
            return (
                <div className={_styles.imageSectionQM}>
                    <img src={imageUrl?.medium} alt='' />
                    <br />
                </div>
            );
        } else if (quickViewModeData?.images?.length && quickViewModeData?.images[0]?.small) {
            return (
                <div className={_styles.imageSection}>
                    {hotelType && <span>{hotelType}</span>}
                    <img src={quickViewModeData.images[0].small} alt='' />
                    <br />
                </div>
            );
        } else {
            return (
                <div className={_styles.imageSection}>
                    {hotelType && <span>{hotelType}</span>}
                    <img src={imageUrl?.small} alt='' />
                    <br />
                </div>
            );
        }
    };

    return (
        <div
            className={cn(
                quickViewMode ? _styles.cardLayoutQM : _styles.cardLayout,
                { 'is-focused': focused, [_styles.focused]: focused },
                classes.wrapper
            )}
            itemScope={true}
            data-id={id}
            itemType='http://schema.org/LocalBusiness'
            ref={(element) => {
                onRefSet && onRefSet(element);
                containerRef.current = element;
            }}
        >
            <div className={quickViewMode ? _styles.cardContentQM : _styles.cardContent}>
                {/* Image Section */}
                {renderImageSection()}

                {/* Info Section */}
                <div className={quickViewMode ? _styles.infoSectionQM : _styles.infoSection}>
                    <div className={_styles.infoTitle} onClick={onQuickViewClick}>
                        <TextTruncate line={2} element='span' truncateText='…' text={name} />
                    </div>

                    {quickViewMode && quickViewModeDataLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            {/* Info Location */}
                            <div className={_styles.infoLocation}>
                                <div className={_styles.infoText}>
                                    {city}, {state}
                                    {distance && (
                                        <span className={_styles.distance}>
                                            {' | '}
                                            <MapPinIcon color='#69717A' size='s' />
                                            {`${distance} miles from venue`}
                                        </span>
                                    )}
                                </div>
                            </div>

                            {/* Info Rating */}
                            {ratingValue && ratingType && (
                                <div className={_styles.infoRating}>
                                    <span>Hotel Rating:</span>
                                    <RatingCSN type={ratingType} value={ratingValue} />
                                </div>
                            )}

                            {/* Rooms Section */}
                            <div className={_styles.roomsSection}>
                                {quickViewMode && (
                                    <span className={_styles.simpleText}>
                                        {hotelType} in {city}, {state}
                                    </span>
                                )}
                                {!!guestRooms && <span>Guest Rooms: {guestRooms}</span>}
                                {quickViewMode && (
                                    <>
                                        {!!quickViewModeData?.rooms?.single && <span>Single Rooms: {quickViewModeData.rooms.single}</span>}
                                        {!!quickViewModeData?.rooms?.double && <span>Double Rooms: {quickViewModeData.rooms.double}</span>}
                                        {!!quickViewModeData?.rooms?.suite && <span>Suites: {quickViewModeData.rooms.suite}</span>}
                                        {!!quickViewModeData?.chain && (
                                            <span className={cn(_styles.fullRow, _styles.firstFullRow)}>
                                                Chain: {quickViewModeData.chain}
                                            </span>
                                        )}
                                        {!!quickViewModeData?.brand && (
                                            <span className={_styles.fullRow}>Brand: {quickViewModeData.brand}</span>
                                        )}
                                        {!!quickViewModeData?.build && (
                                            <span className={_styles.fullRow}>Build: {quickViewModeData.build}</span>
                                        )}
                                        {!!quickViewModeData?.renovated && (
                                            <span className={_styles.fullRow}>Renovated: {quickViewModeData.renovated}</span>
                                        )}
                                        {!!quickViewModeData?.distanceFromAirport && (
                                            <span className={_styles.fullRow}>
                                                {`Distance from airport:
                                                ${quickViewModeData.distanceFromAirport?.toFixed(2)}
                                            mi `}
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>

                            {quickViewMode && quickViewModeData?.description && (
                                <InfoDescriptionComponent description={quickViewModeData?.description} />
                            )}

                            {/* Info Amenities */}
                            {quickViewMode && quickViewModeData?.amenities && (
                                <div className={_styles.infoAmenities}>
                                    <span>Amenities</span>
                                    <div className={_styles.infoAmenitiesContainer}>
                                        {quickViewModeData.amenities.map((x, i) => (
                                            <span key={i}>{x}</span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Info Buttons */}
            <div className={quickViewMode ? _styles.buttonsSectionQM : _styles.buttonsSection}>
                {fromVenuePageProps ? (
                    <div className={_styles.selectButton}>
                        <button onClick={fromVenuePageProps.onSubmit}>Submit Request</button>
                    </div>
                ) : (
                    <div className={cn(_styles.selectButton, { [_styles.selectButtonSelected]: selected })}>
                        <button onClick={() => (selected ? removeItem(id) : addItem(propsToCartItem(props)))}>
                            {!selected ? (
                                'Select Hotel'
                            ) : (
                                <>
                                    <CheckIcon color='white' />
                                    Selected
                                </>
                            )}
                        </button>
                    </div>
                )}

                <div className={_styles.quickViewButton}>
                    <button onClick={onQuickViewClick}>
                        <span>{`${quickViewMode ? 'Close' : ''} Quick View`}</span>
                    </button>
                </div>
            </div>
        </div>
    );
});
