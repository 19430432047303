import './slick.scss';
import './slick-theme.scss';

import Slick, { Settings } from 'react-slick';
import * as React from 'react';
import cn from 'classnames';
import { RingsSpinner } from '../rings-spinner/RingsSpinner';
import Slide from '../slider-slide';

import styles from './Slider.module.scss';

export interface SliderImage {
    src: string;
    credit?: string;
}

export interface IProps {
    images: SliderImage[];
    videos?: string[];
    venueId?: number;
    venueName: string;
    venueRegion?: string;
    renderCtaSlide?: boolean;
    isPriced?: boolean;
    estimateComplete?: boolean;
    slickSettingsOverride?: Settings;
    showCounter?: boolean;
    forceWebStyle?: boolean;
    itemProp?: boolean;
    onActiveImageChange: (index: number) => void;
    onNextClick?: () => void;
    onPrevClick?: () => void;
    onReportClick?: (currentImage: SliderImage | null) => void;
    onContactClick?: () => void;

    startImageLoad?: number;
    stepImageLoad?: number;

    // First method of lazy loading which is to download the current visible image
    // then download the images surrounding it. Trigger further downloads as the user
    // performs scroll animations
    profileImagesLazyLoad?: boolean;

    // Allows external control of when images should be loaded by influencing when
    // the slide with the image is rendered in the DOM
    dontLoadImagesYet?: boolean;
    hidePhotoCredits?: boolean;
    settings?: object;
    venueProfileVersionThree?: boolean;

    classes?: {
        sliderContainerWrapper?: string;
        sliderImageWrapper?: string;
        slideContainerWrapper?: string;
        slideImageWrapper?: string;
        imageWrapper?: string;
    };
}

export interface IState {
    curVideoStarted: boolean;
    videoPlaying: boolean;
    leftmostLoadedSlide: number;
    rightmostLoadedSlide: number;
    imagesLoaded: number;
    fullyLoadedImages: Set<number>;
    activeImageIndex: number;
}

interface SlideConfig {
    imageURL: string | undefined;
    videoURL: string | undefined;
}

const defaultSliderProps = {
    startImageLoad: 5,
    stepImageLoad: 3,
};

type SliderDirection = 'left' | 'right';

export class Slider extends React.Component<IProps, IState> {
    private slider: Slick;
    private readonly settings: Settings;
    private isTransitioning = false;
    private transitionDirection: SliderDirection | undefined;
    static whyDidYouRender = true;

    timer: number;

    constructor(props: IProps) {
        super(props);

        this.handleNextClick = this.handleNextClick.bind(this);
        this.handlePrevClick = this.handlePrevClick.bind(this);
        this.handleSlideSwipe = this.handleSlideSwipe.bind(this);
        this.handleSlideChangeToNext = this.handleSlideChangeToNext.bind(this);
        this.handleSlideChangeToPrev = this.handleSlideChangeToPrev.bind(this);
        this.transitionStarted = this.transitionStarted.bind(this);
        this.transitionEnded = this.transitionEnded.bind(this);
        this.curVideoStarted = this.curVideoStarted.bind(this);
        this.curVideoStopped = this.curVideoStopped.bind(this);
        this.onVideoPlayed = this.onVideoPlayed.bind(this);
        this.onVideoStopped = this.onVideoStopped.bind(this);
        this.onSlideLoaded = this.onSlideLoaded.bind(this);
        this.handleChangeSlide = this.handleChangeSlide.bind(this);
        this.processChangedSlide = this.processChangedSlide.bind(this);
        this.getSlidesCount = this.getSlidesCount.bind(this);

        this.settings = {
            dots: false,
            accessibility: false,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            afterChange: this.transitionEnded,
            beforeChange: this.transitionStarted,
            ...this.props.slickSettingsOverride,
        };
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    state: IState = {
        curVideoStarted: false,
        videoPlaying: false,
        leftmostLoadedSlide: -1,
        rightmostLoadedSlide: Math.max((this.props.startImageLoad || defaultSliderProps.startImageLoad) - 1, 0),
        imagesLoaded: 0,
        fullyLoadedImages: new Set(),
        activeImageIndex: 0,
    };

    public render() {
        const curImg =
            this.state.activeImageIndex < this.props.images.length ? this.props.images[this.state.activeImageIndex] : this.props.images[0]; // Render first image + credits
        const imagesCount = this.props.images.length + (this.props.videos ? this.props.videos.length : 0);
        const currentIndex = imagesCount ? this.state.activeImageIndex + 1 : 0;

        let slides: SlideConfig[] = [];

        if (this.props.videos) {
            const firstVideo = this.props.videos[0];

            // Add first video w/ first image as preview
            if (!!firstVideo) {
                const firstImg = this.props.images[0];
                slides.push({
                    imageURL: !!firstImg ? firstImg.src : undefined,
                    videoURL: firstVideo,
                });
            }
        }

        // Add images to list
        slides = slides.concat(
            this.props.images.map((img, i) => ({
                imageURL: img.src,
                videoURL: undefined,
            }))
        );

        if (this.props.videos) {
            const restVideos = this.props.videos.slice(1);

            // Add rest of the videos
            slides = slides.concat(
                restVideos.map((v) => ({
                    imageURL: undefined,
                    videoURL: v,
                }))
            );
        }

        const renderDotNav = () => {
            return (
                <>
                    <div className={styles.dotNavigation} onClick={this.handlePrevClick} />
                    <div className={cn(styles.dotNavigation, styles.dotNavigationActive)} />
                    <div className={styles.dotNavigation} onClick={this.handleNextClick} />
                </>
            );
        };

        const isPlaceholderShown = slides.length === 0;

        const renderPhotoCredits = () => {
            return (
                <div className={styles.photoCredit}>
                    <span className={styles.photoCreditText}>{curImg && curImg.credit}</span>
                    <div
                        className={styles.reportCreditLink}
                        onClick={() => {
                            this.props.onReportClick && this.props.onReportClick(curImg);
                        }}
                    >
                        <span className={styles.reportCreditLinkText}> Report </span>
                        <i className='icon-hb-nx-megaphone' />
                    </div>
                </div>
            );
        };
        return !isPlaceholderShown ? (
            <div className={this.props.classes?.sliderContainerWrapper || styles.className}>
                <div className={this.props.classes?.sliderImageWrapper || styles.imageWrapper}>
                    <Slick {...this.settings} ref={(slider: Slick) => (this.slider = slider)} onSwipe={this.handleSlideSwipe}>
                        {slides.map((s, i) => (
                            <Slide
                                key={i}
                                imageURL={s.imageURL}
                                videoURL={s.videoURL}
                                slideActive={this.state.activeImageIndex === i}
                                onPlayButton={this.curVideoStarted}
                                onVideoPlayed={this.onVideoPlayed}
                                onVideoStopped={this.onVideoStopped}
                                venueName={this.props.venueName}
                                venueRegion={this.props.venueRegion}
                                show={this.determineSlideVisibility(i)}
                                onImageLoad={() => this.onSlideLoaded(i)}
                                forceLoad={this.shouldOverrideLazy(i)}
                                itemProp={this.props.itemProp && i === 0 ? this.props.itemProp : undefined}
                                classes={{
                                    slideContainerWrapper: this.props.classes?.slideContainerWrapper,
                                    slideImageWrapper: this.props.classes?.slideImageWrapper,
                                    imageWrapper: this.props.classes?.imageWrapper,
                                }}
                            />
                        ))}
                        {this.props.renderCtaSlide && this.renderLastSlide(slides)}
                    </Slick>
                    {slides && slides.length > 1 && !this.props.dontLoadImagesYet && (
                        <>
                            <div
                                className={cn(styles.controls, {
                                    [styles.videoRendered]:
                                        this.state.curVideoStarted &&
                                        slides[this.state.activeImageIndex] &&
                                        !!slides[this.state.activeImageIndex].videoURL,
                                })}
                            >
                                <div className={styles.navigationWrapper}>
                                    <div className={styles.triangleLeft} onClick={this.handlePrevClick} />

                                    {this.settings.dots ? '' : renderDotNav()}

                                    <div className={styles.triangleRight} onClick={this.handleNextClick} />
                                </div>
                                {currentIndex <= imagesCount && (
                                    <div className={styles.counter}>
                                        {currentIndex}/{imagesCount}
                                    </div>
                                )}
                            </div>
                            <div
                                className={cn(styles.arrowLeft, {
                                    [styles.arrowLeftVideoPlaying]:
                                        this.state.videoPlaying &&
                                        !!slides[this.state.activeImageIndex] &&
                                        !!slides[this.state.activeImageIndex].videoURL,
                                    [styles.arrowRightVideoStopped]:
                                        !this.state.videoPlaying &&
                                        !!slides[this.state.activeImageIndex] &&
                                        !!slides[this.state.activeImageIndex].videoURL,
                                })}
                                onClick={this.handlePrevClick}
                            >
                                <i className='icon-hb-arrowleft-b' />
                            </div>
                            <div
                                className={cn(styles.arrowRight, {
                                    [styles.arrowRightVideoPlaying]:
                                        this.state.videoPlaying &&
                                        !!slides[this.state.activeImageIndex] &&
                                        !!slides[this.state.activeImageIndex].videoURL,
                                    [styles.arrowLeftVideoStopped]:
                                        !this.state.videoPlaying &&
                                        !!slides[this.state.activeImageIndex] &&
                                        !!slides[this.state.activeImageIndex].videoURL,
                                })}
                                onClick={this.handleNextClick}
                            >
                                <i className='icon-hb-arrowright-b' />
                            </div>
                        </>
                    )}
                </div>

                {this.props.hidePhotoCredits ? '' : renderPhotoCredits()}
            </div>
        ) : this.props.venueProfileVersionThree ? (
            renderPhotoCredits()
        ) : (
            <div className={this.props.classes?.slideContainerWrapper || styles.className}>
                <div className={styles.placeholder}>
                    <RingsSpinner animate={false} />
                </div>
            </div>
        );
    }

    private onSlideLoaded(index: number) {
        this.setState((state) => {
            state.fullyLoadedImages.add(index);
            return { imagesLoaded: state.imagesLoaded + 1 };
        });
    }

    /**
     * This determines how the underlying lazy load image given by the index parameter should behave.
     * See SlideImage.Props.forceLoad documentation for more details
     */
    private shouldOverrideLazy(index: number) {
        if (!this.props.profileImagesLazyLoad) {
            return true;
        }

        // Curent active image should lazy load (i.e. load when visible)
        // this is important so that buffered images will be loaded shortly after
        if (index === this.state.activeImageIndex) {
            return undefined;
        }

        // Images that should be "shown" (given by determineSlideVisibility) are ones that need
        // to be preloaded (buffered) for smooth carousel scrolling behavior, because of the underlying
        // lazy loading image won't load until visible, so we need to determine if we need to force it to
        // load, which only needs to happen if the image is both shown and the visible image has already loaded
        const res = this.determineSlideVisibility(index) && this.state.fullyLoadedImages.has(this.state.activeImageIndex);

        return res;
    }

    private determineSlideVisibility(index: number): boolean {
        if (this.props.dontLoadImagesYet) {
            return false;
        }

        if (!this.props.profileImagesLazyLoad) {
            // control lazy loading globally
            return true;
        }

        const slidesCount = this.props.images.length + (this.props.videos ? this.props.videos.length : 0);
        if (index <= this.state.rightmostLoadedSlide) {
            return true;
        } else {
            return index >= slidesCount + this.state.leftmostLoadedSlide;
        }
    }

    public renderLastSlide(slides: SlideConfig[]) {
        if (slides.length === 0) {
            return;
        }

        const firstSlide = slides[0];

        let slideTextContent, slideButton;
        if (this.props.estimateComplete) {
            slideTextContent = (
                <div className={styles.ctaText}>
                    Don't miss out! <br />
                    Send a message now and receive more information.
                </div>
            );
        } else if (this.props.isPriced) {
            slideTextContent = (
                <div className={styles.ctaText}>
                    Price this venue to get your estimate, <br />
                    no strings attached.
                </div>
            );
        }

        if (this.props.estimateComplete) {
            slideButton = (
                <button className={cn('nx-button', 'nx-button--primary', styles.ctaButton)} onClick={this.props.onContactClick}>
                    CONTACT VENUE
                </button>
            );
        } else if (this.props.isPriced) {
            slideButton = (
                <a
                    href={`/pricing/${this.props.venueId}/estimate/`}
                    className={cn('nx-button', 'nx-button--primary', styles.ctaButton)}
                    rel='nofollow'
                >
                    PRICE THIS VENUE
                </a>
            );
        } else {
            slideButton = (
                <button className={cn('nx-button', 'nx-button--primary', styles.ctaButton)} onClick={this.props.onContactClick}>
                    REQUEST A QUOTE
                </button>
            );
        }

        return (
            <div className={styles.ctaSlideContainer}>
                <div className={styles.ctaWrapper}>
                    {slideTextContent}
                    {slideButton}
                </div>
                <Slide imageURL={firstSlide.imageURL} venueName={this.props.venueName} venueRegion={this.props.venueRegion} />
            </div>
        );
    }

    private handleChangeSlide(e: React.SyntheticEvent, direction: SliderDirection) {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if (this.isTransitioning) {
            return;
        }

        this.isTransitioning = true;
        this.transitionDirection = direction;
        direction === 'right' ? this.slider.slickNext() : this.slider.slickPrev();
    }

    private handleNextClick(e: React.SyntheticEvent) {
        this.handleChangeSlide(e, 'right');
    }

    private handlePrevClick(e: React.SyntheticEvent) {
        this.handleChangeSlide(e, 'left');
    }

    private handleSlideSwipe(direction: SliderDirection) {
        this.isTransitioning = true;
        direction === 'right' ? this.handleSlideChangeToPrev() : this.handleSlideChangeToNext();
    }

    private handleSlideChangeToPrev() {
        const stepImageLoad = this.props.stepImageLoad || defaultSliderProps.stepImageLoad;
        const slidesCount = this.getSlidesCount();
        const stateProps: Pick<IState, 'leftmostLoadedSlide'> = { leftmostLoadedSlide: this.state.leftmostLoadedSlide };
        if (this.state.activeImageIndex === 0 && this.state.leftmostLoadedSlide === -1) {
            // load 3 more images on first slide change
            stateProps.leftmostLoadedSlide = this.state.leftmostLoadedSlide - stepImageLoad;
        } else if (
            // having at least step+1 loaded images in advance
            // adding 1 for last slide
            slidesCount + this.state.leftmostLoadedSlide >= this.state.activeImageIndex - stepImageLoad - 1 &&
            // if adjusted leftmostLoadedSlide is bigger than activeIndex
            // it means it means that we were going in opposite direction
            // before and already loaded these images
            slidesCount + this.state.leftmostLoadedSlide <= this.state.activeImageIndex
        ) {
            stateProps.leftmostLoadedSlide = this.state.leftmostLoadedSlide - 1;
        }
        this.processChangedSlide(this.getNextSlideIndex('left'), stateProps);
    }

    private processChangedSlide(idx: number, stateProps: Partial<Pick<IState, 'rightmostLoadedSlide' | 'leftmostLoadedSlide'>>) {
        this.props.onActiveImageChange(idx);
        type props = typeof stateProps;
        this.setState((state) => {
            return {
                activeImageIndex: idx,
                ...(stateProps as Required<props>),
            };
        });
    }

    private getSlidesCount() {
        return this.props.images.length + (this.props.videos ? this.props.videos.length : 0) + (this.props.renderCtaSlide ? 1 : 0);
    }

    private getNextSlideIndex(direction: SliderDirection) {
        const slidesCount = this.getSlidesCount();
        const nextIndex = (this.state.activeImageIndex + (direction === 'right' ? 1 : -1)) % slidesCount;
        return nextIndex < 0 ? nextIndex + slidesCount : nextIndex;
    }

    private handleSlideChangeToNext() {
        const stepImageLoad = this.props.stepImageLoad || defaultSliderProps.stepImageLoad;
        const stateProps: Pick<IState, 'rightmostLoadedSlide'> = { rightmostLoadedSlide: this.state.rightmostLoadedSlide };

        if (this.state.activeImageIndex === 0 && this.state.rightmostLoadedSlide < this.state.rightmostLoadedSlide + stepImageLoad) {
            // load step more images on first slide change
            stateProps.rightmostLoadedSlide = this.state.rightmostLoadedSlide + stepImageLoad;
        } else if (
            // having at least step+1 loaded images in advance
            this.state.rightmostLoadedSlide <= this.state.activeImageIndex + stepImageLoad + 1 &&
            // if rightmostLoadedSlide is less than activeIndex
            // it means it means that we were going in opposite direction
            // before and already loaded these images
            this.state.rightmostLoadedSlide >= this.state.activeImageIndex
        ) {
            // note: we gonna end with slideNum + 5 but we don't really care
            stateProps.rightmostLoadedSlide = this.state.rightmostLoadedSlide + 1;
        }
        this.processChangedSlide(this.getNextSlideIndex('right'), stateProps);
    }

    private transitionStarted() {
        this.curVideoStopped();
    }

    private transitionEnded() {
        if (this.transitionDirection === 'left') {
            this.handleSlideChangeToPrev();
        }
        if (this.transitionDirection === 'right') {
            this.handleSlideChangeToNext();
        }
        this.transitionDirection = undefined;
        this.isTransitioning = false;
    }

    private curVideoStarted() {
        if (this.state.curVideoStarted) {
            this.setState({ curVideoStarted: true });
        }
    }

    private curVideoStopped() {
        if (this.state.curVideoStarted === true) {
            this.setState({ curVideoStarted: false });
        }
    }

    private onVideoPlayed() {
        if (this.state.curVideoStarted === false || this.state.videoPlaying === false) {
            this.setState({ videoPlaying: true, curVideoStarted: true });
        }
    }

    private onVideoStopped() {
        if (this.state.videoPlaying) {
            this.setState({ videoPlaying: false });
        }
    }
}

export default Slider;
