import { AuthProvider } from '@weddingspot/ws-api-client'
import { StorableDataKeys } from '../../utils/storage';
import { MAX_IDLE_DURATION, SESSION_ID_RENEWAL_TIMEOUT, UserAuthStatus } from './constant';


/**
 * Extends the user's session at an regular interval by hitting the endpoint api/v1/session-extend/
 */
export const extendSessionAtInterval = () => {
    setInterval(function () {
        AuthProvider.isLoggedIn().then((userAuthStatus) => {
            if (userAuthStatus === UserAuthStatus.ANONYMOUS) {
                return;
            }
            AuthProvider.extendSession();
        })
    }, SESSION_ID_RENEWAL_TIMEOUT)
}


/**
 * Handle timeout to display session-extend modal by clearing previous timeout and setting new timeout, on occurence of activities on client side
 * 
 * @param setIsOpen sets the state variable 'isOpen' that decides if the session-extend modal/widget is open or close
 */
export const handleTimeoutToDisplaySessionExtendWidget = (setIsOpen:any)=>{
    
    const displayExtendSessionWidget = () => {
        
        const userLastActivity = parseInt(localStorage.getItem(StorableDataKeys.USER_LAST_ACTIVITY)!, 10);
        const currentTime = Date.now();
       
        if(currentTime-userLastActivity < MAX_IDLE_DURATION) {
            clearTimeout(timeOutToDisplayExtendSessionScreen);
            const timeout = (userLastActivity + MAX_IDLE_DURATION) - currentTime;
            timeOutToDisplayExtendSessionScreen = setTimeout( displayExtendSessionWidget,timeout);
            return;
        }
        AuthProvider.isLoggedIn().then((userAuthStatus)=>{
            if(userAuthStatus === UserAuthStatus.ANONYMOUS) {
                return;
            }
            setIsOpen(true);
        })
    };

    let timeOutToDisplayExtendSessionScreen = setTimeout( displayExtendSessionWidget, MAX_IDLE_DURATION)
    
    const eventList = ['scroll', 'click', 'mousemove', 'keydown']
    eventList.map((event)=>{
        window.addEventListener(event, function () {
            clearTimeout(timeOutToDisplayExtendSessionScreen)
            localStorage.setItem(StorableDataKeys.USER_LAST_ACTIVITY,Date.now().toString())
            timeOutToDisplayExtendSessionScreen = setTimeout( displayExtendSessionWidget, MAX_IDLE_DURATION)
        }, false);
    })
}