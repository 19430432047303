import { Dispatch } from 'redux';
import * as constants from './constants';

import { Venues, VendorsApi } from '@weddingspot/ws-api-client';

import { InquiryType } from '../appointments/constants';

export interface VenueRecommendationsLoaded {
    type: constants.VENUE_RECOMMENDATIONS_LOADED;
    payload: Venues.VenueRecommendation[];
}

export interface HotelRecommendationsLoaded {
    type: constants.HOTEL_RECOMMENDATIONS_LOADED;
    payload: Venues.HotelRecommendation[];
}

export interface DismissBanner {
    type: constants.VENUE_RECOMMENDATIONS_DISMISS_BANNER;
}

export interface VenueRecommendationsRequested {
    type: constants.VENUE_RECOMMENDATIONS_REQUESTED;
    payload: InquiryType;
}

export function loadVenueRecommendationsAction(venueId: number, excludeIds?: number[], count?: number, inquiryType?: InquiryType) {
    return function (dispatch: Dispatch<VenueRecommendationsLoaded>) {
        let postData: Venues.VenueRecommendationsQueryParams = {
            seed_ids: [venueId],
            count: count || 6,
            exclude_ids: excludeIds,
            // show_estimates: true
        };

        if (!!inquiryType && inquiryType === InquiryType.REHEARSAL_DINNER) {
            postData.rdvs = 1;
        }

        VendorsApi.getVenueRecommendations(postData).then((resp: Venues.VenueRecommendation[]) => {
            dispatch({
                type: constants.VENUE_RECOMMENDATIONS_LOADED,
                payload: resp,
            });
        });
    };
}

export function loadHotelRecommendationsAction(venueId: number, limit?: number) {
    return function (dispatch: Dispatch<HotelRecommendationsLoaded>) {
        let postData: Venues.HotelRecommendationsQueryParams = {
            ws_seed_ids: [venueId],
            limit: limit || 6,
        };

        VendorsApi.getHotelRecommendations(postData).then((resp: Venues.HotelRecommendation[]) => {
            dispatch({
                type: constants.HOTEL_RECOMMENDATIONS_LOADED,
                payload: resp,
            });
        });
    };
}

export function dismissBannerAction() {
    return function (dispatch: Dispatch<DismissBanner>) {
        dispatch({
            type: constants.VENUE_RECOMMENDATIONS_DISMISS_BANNER,
        });
    };
}

export function recommendationsRequested(recType: InquiryType) {
    return function (dispatch: Dispatch<VenueRecommendationsRequested>) {
        dispatch({
            type: constants.VENUE_RECOMMENDATIONS_REQUESTED,
            payload: recType,
        });
    };
}

export type VenueRecommendationsActions =
    | VenueRecommendationsLoaded
    | HotelRecommendationsLoaded
    | DismissBanner
    | VenueRecommendationsRequested;
