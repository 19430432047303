import { CartItem } from './service';
import { RatingProps } from '../csn/Rating';

const image =
    'https://images.cvent.com/ws/images/venues/4149/Muckenthaler-Mansion-wedding-Fullerton-CA-185161-orig.1502318031.jpg?unique=true';

const mockCartItems: CartItem[] = [];

export const mockGetCartSuccess = () => {
    return Promise.resolve(mockCartItems);
};

export const mockGetCartUnauth = () => {
    return Promise.reject({ detail: 'Not authorized', status: 401 });
};

export const mockGetCartItemsFail = () => {
    return Promise.reject({ error: 'no cart items' });
};

export const mockSaveCartSuccess = () => {
    return Promise.resolve(true);
};

export const mockRemoveCartItem = (id: string | number) => {
    return Promise.resolve(true);
};

export const mockAddCartItem = (i: CartItem) => {
    return Promise.resolve(true);
};
