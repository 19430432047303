import * as React from 'react';
import ReactPlayer from 'react-player';

import cls from 'classnames';
import { RingsSpinner } from '../rings-spinner/RingsSpinner';
import SpinnerOverlay from '../spinner-overlay/index';
import SlideImage from './SlideImage';

const styles = require('./SliderSlide.scss');

export interface Props {
    // Whether or not the current slide is the one that's visible
    slideActive: boolean;
    // URL of the image to show on the slide
    imageURL?: string;
    // URL of the video if applicable. This should be used together with imageURL to show a placeholder
    videoURL?: string;
    // Event handler for video play button control
    onPlayButton?: () => void;
    // Event handler for when the video is stopped/paused
    onVideoStopped?: () => void;
    // Event handler for when the video is started/played
    onVideoPlayed?: () => void;
    // Name of venue (used for alt tags)
    venueName: string;
    // Region of venue (used for alt tags)
    venueRegion: string;
    // Whether or not to show the schema.org itemprop
    itemProp?: boolean;
    /* Whether or not to render anything at all in the slide, this is used to indicate that
    the slide should being loading images */
    show?: boolean;
    /* Event handler for when the image has been downloaded completely and loaded,
    this is used to determine which images should load when the user slides through the carousel */
    onImageLoad?: () => void;
    // true forces the underlying lazy image to load immediately if set to true
    // undefined value means the image will only load if visible in viewport
    // false will not render the image element in the markup
    forceLoad?: boolean;

    classes?: {
        slideContainerWrapper?: string;
        slideImageWrapper?: string;
        imageWrapper?: string;
    };
}

export interface State {
    showLoadingSpinner: boolean;
    imgLoaded: boolean;
    videoPlaying: boolean;
}

export default class Slide extends React.Component<Props, State> {
    static defaultProps: Partial<Props> = {
        show: true,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            showLoadingSpinner: false,
            imgLoaded: false,
            videoPlaying: false,
        };

        this.handlePlayButtonClick = this.handlePlayButtonClick.bind(this);
        this.showLoadingSpinner = this.showLoadingSpinner.bind(this);
        this.hideLoadingSpinner = this.hideLoadingSpinner.bind(this);
        this.onSlideLoaded = this.onSlideLoaded.bind(this);
        this.onVideoReady = this.onVideoReady.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        // Handle the case where a video might have started, but the slide was navigated away.
        // This is necessary because the player state is maintained internally here, but needs
        // to alwo be aware of whether or not it needs to "rest" the video player after the
        // user navigates away from it in the slider
        if (prevProps.slideActive && !this.props.slideActive && this.state.videoPlaying) {
            this.setState({ videoPlaying: false });
        }
    }

    public render() {
        const { imageURL, videoURL } = this.props;

        return (
            <div className={cls(this.props.classes?.slideContainerWrapper || styles.className, { [styles.hasVideo]: !!videoURL })}>
                {!!this.props.show && videoURL !== undefined && this.props.slideActive && this.renderVideo(videoURL, imageURL)}
                {!!this.props.show && !!imageURL && !this.state.videoPlaying && (
                    <div className={this.props.classes?.slideImageWrapper || styles.imageContainer}>
                        <SlideImage
                            alt={this.props.venueRegion ? this.props.venueName + ' wedding ' + this.props.venueRegion : ''}
                            src={imageURL}
                            hidden={!this.state.imgLoaded}
                            onLoad={this.onSlideLoaded}
                            forceLoad={this.props.forceLoad}
                            itemProp={this.props.itemProp}
                            class={this.props.classes?.imageWrapper}
                        />
                    </div>
                )}
                {!!this.props.show && !this.state.imgLoaded && !videoURL && (
                    <div className={styles.spinnerContainer}>
                        <RingsSpinner animate={true} />
                    </div>
                )}
                {!this.props.show && (
                    <div className={styles.spinnerContainer}>
                        <RingsSpinner animate={true} />
                    </div>
                )}
            </div>
        );
    }

    private onSlideLoaded(): void {
        this.setState({ imgLoaded: true }, () => {
            if (!!this.props.onImageLoad) {
                this.props.onImageLoad();
            }
        });
    }

    private renderVideo(videoUrl: string, imageUrl: string | undefined) {
        if (!!videoUrl && !!imageUrl) {
            return (
                <div className={styles.videoContainer}>
                    {this.state.videoPlaying && (
                        <div className={styles.playerWrapper}>
                            <SpinnerOverlay
                                isSpinning={this.state.showLoadingSpinner}
                                noOpacity={true}
                                spinnerProps={{
                                    spinnerColor: 'white',
                                    spinnerDiameter: 100,
                                }}
                            >
                                <ReactPlayer
                                    onReady={this.hideLoadingSpinner}
                                    url={videoUrl}
                                    playing={this.state.videoPlaying}
                                    width={'100%'}
                                    height={'100%'}
                                    muted={true}
                                    controls={true}
                                    onPlay={() => {
                                        this.props.onVideoPlayed && this.props.onVideoPlayed();
                                    }}
                                    onPause={() => this.props.onVideoStopped && this.props.onVideoStopped()}
                                    onEnded={() => this.props.onVideoStopped && this.props.onVideoStopped()}
                                />
                            </SpinnerOverlay>
                        </div>
                    )}
                    {!this.state.videoPlaying && <a href='#' onClick={this.handlePlayButtonClick} className={styles.playButton} />}
                </div>
            );
        }

        if (!imageUrl) {
            return (
                <div className={styles.videoContainer}>
                    <SpinnerOverlay
                        isSpinning={this.state.showLoadingSpinner}
                        noOpacity={true}
                        overlayClassName={styles.SpinnerOverlay}
                        spinnerProps={{
                            spinnerColor: 'white',
                            spinnerDiameter: 100,
                        }}
                    >
                        <ReactPlayer
                            onReady={this.onVideoReady}
                            url={videoUrl}
                            playing={this.state.videoPlaying}
                            width={'100%'}
                            height={'100%'}
                            muted={false}
                            controls={true}
                            onPlay={() => {
                                this.props.onVideoPlayed && this.props.onVideoPlayed();
                                this.props.onPlayButton && this.props.onPlayButton();
                            }}
                            onPause={() => this.props.onVideoStopped && this.props.onVideoStopped()}
                            onEnded={() => this.props.onVideoStopped && this.props.onVideoStopped()}
                        />
                    </SpinnerOverlay>
                </div>
            );
        }

        return;
    }

    private handlePlayButtonClick(e: React.SyntheticEvent<HTMLAnchorElement>) {
        e.preventDefault();
        this.setState({ videoPlaying: !this.state.videoPlaying });
        this.props.onPlayButton && this.props.onPlayButton();
        this.showLoadingSpinner();
    }

    private showLoadingSpinner() {
        this.setState({ showLoadingSpinner: true });
    }

    private hideLoadingSpinner() {
        this.setState({ showLoadingSpinner: false });
    }

    private onVideoReady() {
        this.hideLoadingSpinner();
        this.onSlideLoaded();
    }
}
