import React from 'react';
import { Venues } from '@weddingspot/ws-api-client';
import { AnalyticsEvent, EventParams, InquiryType, logEvent } from '@weddingspot/ws-analytics';
import cn from 'classnames';
import { toUSD } from '../../utils/string';

import styles from './VenueRecommendations.module.scss';

interface VenueRecommendationProps {
    data: Venues.VenueRecommendation;
    idx: number;
    className?: string;
    onClick?: (id: number) => void;
}

const VenueRecommendation = ({ data, idx, className, onClick }: VenueRecommendationProps) => {
    const clickHandler = () => {
        if (onClick) {
            onClick(data.id);
        }
        window.open(`${window.location.origin}/venue/${data.id}/`, '_blank');
        const location = window?.location?.pathname;
        const source = location.startsWith('/pricing/') ? 'Spot Estimate' :
                        location.startsWith('/venue/') ? 'Venue Profile' :
                        location.startsWith('/wedding-venues/') ? 'SRP' :
                        location;

        logEvent(AnalyticsEvent.EVTNM_CLICK_PROFILE_RECOMMENDATION, {
            [EventParams.POSITION]: idx,
            [EventParams.LOCATION]: source,
            [EventParams.INQUIRY_TYPE]: InquiryType.WEDDING,
            [EventParams.VENUE_ID]: data.id,
        });
    };
    const onEnterPressed = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            clickHandler();
        }
    };

    return (
        <div
            role='button'
            tabIndex={-1}
            aria-hidden='true'
            className={className ? cn(styles.recommendation, { [className]: true }) : styles.recommendation}
            onClick={clickHandler}
            // @ts-ignore
            onKeyPress={onEnterPressed}
        >
            <div className={styles.image}>
                <img src={data.main_image_url} alt='venue' />
            </div>
            <h3>{data.name}</h3>
            <p>{data.region}</p>
            {data.budget_min && data.budget_capacity && <p>{`Starting at ${toUSD(data.budget_min)} for ${data.budget_capacity} guests`}</p>}
        </div>
    );
};

export default VenueRecommendation;
