import * as React from 'react';

import cn from 'classnames';
import { TypeaheadSearchResults } from '../typeahead-search-results';
import { TypeaheadSearchResult } from './store';

import SearchIcon from '@cvent/carina/components/Icon/Search';

import searchIconURL from './icon.svg';

import styles from './Typeahead.module.scss';

interface Props {
    onChange: (q: string) => void;
    inputValue: string;

    locations: TypeaheadSearchResult[];
    styles: TypeaheadSearchResult[];
    venues: TypeaheadSearchResult[];

    isSearchMenuShown: boolean;
    isSearchIconShown?: boolean;
    isSearchIconCarinaShown?: boolean;
    className?: string;
    containerClassName?: string;
    onInputFocus?: () => void;
    onInputClick?: () => void;
    onSearchResultClick?: (link: string, inputValue?: string) => void;
    setVenueId?: (field: string, value: any, shouldValidate?: boolean) => void;
    responsive?: boolean;
    brandColorBackground ?: boolean;
    showVenuesOnly ?: boolean;
    disableVenueRedirection ?: boolean;
    showSearchIcon?: boolean;
}

interface State {
    selectedItemIndex: null | number;
    value: string;
    readonly: boolean;
}

const KEY_DOWN = ['ArrowDown'];
const KEY_UP = ['ArrowUp'];
const KEY_ENTER = ['Enter'];

export class Typeahead extends React.Component<Props, State> {
    readonly state = { selectedItemIndex: null, value: '', readonly: false };

    constructor(props: Props) {
        super(props);
        this.handleKeyStroke = this.handleKeyStroke.bind(this);
    }

    onClickHandler = (e:any) => {
        this.props.onSearchResultClick;
        this.setState({ value: '' });
        if (this.props.setVenueId) { 
            const _e= e.split('/');
            this.setState({readonly: true, value:_e[3].replaceAll('-',' ') }); //Setting venueName as value of the input and making it readonly
            this.props.setVenueId('venueId', parseInt(_e[2])); // setting venueId of the reviewForm using the setState function passed in props
            this.props.onChange(''); //clears the list of venues in the suggestion 
        }
    };
    render() {
        return (
            <div
                data-typeahead={true}
                className={cn(this.props.containerClassName, styles.className, {
                    [styles.hasCarinaIcon]: this.props.isSearchIconCarinaShown,
                    [styles.responsive]: this.props.responsive,
                })}
            >
                {this.props.isSearchIconShown && <img src={searchIconURL?.src || searchIconURL} className={styles.icon} alt= 'Search box to search by venue, location or style'/>}
                {this.props.isSearchIconCarinaShown && (
                    <div className={styles.carinaIcon}>
                        <SearchIcon color='#69717A' size='s' />
                    </div>
                )}
                {/*
                 When showSearchIcon is passed as props, we get to see Carina Search Icon well placed inside the input box. 
                 Upon clicking the searchIcon, value entered gets reset.
                */}
                {this.props.showSearchIcon && (
                    <div className={cn(styles.showSearchIcon)} onClick={() => this.setState({ readonly: false, value: '' })}>
                        <SearchIcon color={this.state.value ?'#881155':'#69717A'} size='s' />
                    </div>
                )}
                <input
                    value={this.state.value}
                    readOnly={this.state.readonly}
                    className={cn(styles.input, this.props.className)}
                    onFocus={this.props.onInputFocus}
                    onChange={(e) => {
                        this.setState({ value: e.target.value });
                        this.props.onChange(e.target.value);
                    }}
                    placeholder={this.props.showVenuesOnly ? 'Search all venues' : 'Search by venue, location or style'}
                    onKeyDown={this.handleKeyStroke}
                    onClick={this.props.onInputClick}
                    aria-label='Search by venue, location, or style'
                />
                <div className={cn(styles.resultsWrapper, { [styles.hidden]: !this.props.isSearchMenuShown })}>
                    <TypeaheadSearchResults
                        venues={this.props.venues}
                        locations={this.props.showVenuesOnly ? [] : this.props.locations}
                        styles={this.props.showVenuesOnly ? [] : this.props.styles}
                        keyboardSelectedIndex={this.state.selectedItemIndex}
                        inputValue={this.props.inputValue}
                        onClick={this.onClickHandler}
                        brandColorBackground={this.props.brandColorBackground}
                        disableVenueRedirection={this.props.disableVenueRedirection}
                    />
                </div>
            </div>
        );
    }

    private handleKeyStroke(e: React.KeyboardEvent<HTMLInputElement>) {
        const key = e.key;
        const maxIndex = this.props.locations.length + this.props.styles.length + this.props.venues.length - 1;
        const curIndex = this.state.selectedItemIndex;

        const nextIndex = curIndex == null ? 0 : (curIndex + 1) % (maxIndex + 1);
        let prevIndex = curIndex == null ? maxIndex : (curIndex - 1) % (maxIndex + 1);
        if (prevIndex < 0) {
            prevIndex += maxIndex + 1;
        }

        if (KEY_DOWN.indexOf(key) > -1) {
            e.preventDefault();
            this.setState({
                selectedItemIndex: nextIndex,
            });
        } else if (KEY_UP.indexOf(key) > -1) {
            e.preventDefault();
            this.setState({
                selectedItemIndex: prevIndex,
            });
        } else if (KEY_ENTER.indexOf(key) > -1) {
            e.preventDefault();

            const hasResults = this.flattendedResults().length;
            if (hasResults) {
                // If no item is already highlighted, first select the first one
                // in the list before navigating
                if (curIndex == null) {
                    this.setState({ selectedItemIndex: 0 });
                }
                const res = this.getSearchResultAtIndex(curIndex || 0) as TypeaheadSearchResult;
                window.location.href = res.link;
            }
        }
    }

    private getSearchResultAtIndex(idx: number) {
        const items = this.flattendedResults();
        if (items.length > idx) {
            return items[idx];
        }
        return undefined;
    }

    private flattendedResults() {
        return [...this.props.locations, ...this.props.styles, ...this.props.venues];
    }
}

export default Typeahead;
