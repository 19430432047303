import * as React from 'react';
import classnames from 'classnames';
import { Navigation } from '@weddingspot/ws-api-client';
import { SubheaderLink } from '../header/types';

import styles from './SubHeader.module.scss';

export interface SubheaderLinksProps {
    curMarket: string;
    subheaderLinks: SubheaderLink[];
}

export default class SubheaderLinks extends React.Component<SubheaderLinksProps> {
    renderLinksColumn(title: string, links: SubheaderLink[], borderLeft: boolean, colIdx: number) {
        const subCols: Array<SubheaderLink[]> = [];

        for (let i = 0; i < links.length / 7; i++) {
            subCols.push(links.slice(i * 7, i * 7 + 7));
        }

        return (
            links && (
                <div key={colIdx} className={classnames(styles.column, { [styles.borderLeft]: borderLeft })}>
                    <div className={styles.columnHeader}>{title}</div>
                    <div className={styles.subcolumnsWrapper}>
                        {subCols.map((items, subColIdx) => (
                            <ul key={subColIdx} className={classnames(styles.linksWrapper, { [styles.multiColumn]: subColIdx > 0 })}>
                                {items.map((item, idx) => (
                                    <li key={idx}>
                                        <a href={item.url}>{item.label}</a>
                                    </li>
                                ))}
                            </ul>
                        ))}
                    </div>
                </div>
            )
        );
    }

    public render() {
        const popularLinks = this.props.subheaderLinks.filter((item) => {
            return item.type === Navigation.SiteHeaderLinkType.popular;
        });

        let styleColumns = {};
        this.props.subheaderLinks.map((item) => {
            if (item.type === Navigation.SiteHeaderLinkType.style) {
                // We cast as string here because the API will guarantee
                // item has a column property, but the API typedef us reused between
                // endpoints making it more flexible than it needs to be for this particular
                // call
                const column = item.column as string;
                if (!(column in styleColumns)) {
                    styleColumns[column] = {};
                }
                styleColumns[column][item.label] = item;
            }
        });

        return (
            <div className={styles.className}>
                <div className={styles.columnsWrapper}>
                    {Object.keys(styleColumns)
                        .sort()
                        .map((column, i) => {
                            let columnItems = styleColumns[column];
                            let sortedItems = Object.keys(columnItems)
                                .sort()
                                .map((key) => columnItems[key]);
                            return this.renderLinksColumn(`Wedding ${column}`, sortedItems, i > 0, i);
                        })}
                    {this.renderLinksColumn("What's Popular", popularLinks, true, Object.keys(styleColumns).length)}
                </div>
                <div className={styles.bottomText}>
                    Not in {this.props.curMarket}? See our other <a href='/locations/'>Locations</a>.
                </div>
            </div>
        );
    }
}
