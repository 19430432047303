import * as React from 'react';
import cn from 'classnames';

import { ResponsiveModalContent, ResponsiveModalContentProps } from './ResponsiveModalContent';
import './scss/index.module.scss';
import { Global } from '../helpers/Global';

export interface ResponsiveModalContainerProps extends ResponsiveModalContentProps {
    // Whether or not to show the modal (via css)
    show: boolean;
    noFullscreen?: boolean;
    // Custom content (don't render any of the standard header/body/footer containers,
    // just the modal container/content/background
    customContent?: React.ReactNode;
    className?: string; // Probably any acceptable argument for classNames?
    bodyClassName?: string;
    children?: React.ReactNode;
}

export const ResponsiveModalContainer = (props: ResponsiveModalContainerProps) => {
    return (
        <div className={cn('ResponsiveModal', { ResponsiveModal__nofullscreen: props.noFullscreen }, props.className)}>
            {props.show && <Global preventScrolling='always' />}
            <div className={cn('ResponsiveModal--background', 'nx-modal-bg', { 'nx-modal-bg--active': props.show })} />
            <div
                data-testid={'ResponsiveModal'}
                className={cn('ResponsiveModal', 'nx-modal', { 'nx-modal--active': props.show }, props.bodyClassName)}
            >
                <div>
                    <aside className='ResponsiveModal--container nx-modal__container'>
                        {/* Translucent background */}
                        <div
                            data-testid={'ResponsiveModal-background'}
                            className='ResponsiveModal--background nx-modal__background-close'
                            onClick={props.handleClickModalBackground}
                        />

                        <div className='ResponsiveModal--content nx-modal__content'>
                            {props.customContent ? props.customContent : <ResponsiveModalContent {...props} />}
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    );
};
