import * as React from 'react';
import cls from 'classnames';
import { Formik, Form, Field, FormikProps, FormikHelpers as FormikActions } from 'formik';

import PageOverlay from '../page-overlay';

import { WSAppContext, WSAppContextType } from '../../providers/ws-app';
import { Auth } from '@weddingspot/ws-api-client';
import { LocalStorage, StorableDataKeys } from '../..';

const genericSignupError = 'An error occurred while attempting to sign up, please try again later';
import styles from './ModalSignup.module.scss';
import { RECAPTCHA_SITE_KEY } from '../../config';
import { YOUR_PRIVACY_CHOICES, YOUR_PRIVACY_CHOICES_URL } from '../appointments/constants';

export interface SignupModalFormData {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirm: string;
    captcha: string | boolean;
    grecaptcha_response: string;
}

export interface Props {
    headerText: string;
    subheaderText: string;

    onCreateAccountSuccess?: () => void;
}

export interface State {
    facebookError: string | undefined;
    loadFb: boolean;
}

export default class SignupModal extends React.Component<Props, State> {
    static contextType: React.Context<WSAppContextType> = WSAppContext;
    // @ts-ignore
    context!: React.ContextType<typeof WSAppContext>;

    static defaultProps = {
        headerText: 'Find Your Perfect Wedding Venue',
        subheaderText: 'Search, price & compare thousands of venues.',
    };

    constructor(props: Props) {
        super(props);

        this.handleAuthClick = this.handleAuthClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.state = {
            facebookError: undefined,
            loadFb: false,
        };
    }

    componentDidMount() {
        if (!this.state.loadFb && this.context.signupModalOpen) {
            this.setState({ loadFb: true });
        }
    
        const grecaptcha = document.createElement('script');
        grecaptcha.type = 'text/javascript';
        grecaptcha.async = true;
        grecaptcha.defer = true;
        grecaptcha.src = 'https://www.google.com/recaptcha/api.js?onload=recaptchaCallbackSignupForm&render=explicit';
        document.head.appendChild(grecaptcha);

        const callbackMethod = document.createElement('script');
        callbackMethod.type = 'text/javascript';
        callbackMethod.text = `
        var recaptchaCallbackSignupForm = function () {
            let recaptcha_widget_id_signup_inquiry_form = grecaptcha.render('signup_form_recaptcha',{
                'sitekey':'${RECAPTCHA_SITE_KEY}', 
                'callback': function (response) {
                    document.getElementById('signup-form-hidden-input').click();
                },
                'expired-callback': function (response) {
                    document.getElementById('signup-form-hidden-input').click(); 
                }
            })
            localStorage.setItem('${StorableDataKeys.RECAPTCHA_WIDGET_ID_SIGNUP_INQUIRY_FORM}', recaptcha_widget_id_signup_inquiry_form);
        };
        `
        document.head.appendChild(callbackMethod);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!prevState.loadFb && this.context.signupModalOpen) {
            this.setState({ loadFb: true });
        }
    }

    public render() {
        const { rootNodeId, isForceAuth, closeSignupModal } = this.context;
        return (
            <PageOverlay
                rootNodeId={rootNodeId}
                onClick={isForceAuth ? () => {} : closeSignupModal}
                isVisible={this.context.signupModalOpen}
            >
                <div className={styles.className}>
                    <div className={styles.headerContainer}>
                        {!isForceAuth && (
                            <span
                                className={cls(styles.closeBtn, 'icon', 'icon-hb-nx-close')}
                                onClick={this.context.closeSignupModal}
                                data-testid='ModalSignup--closeButon'
                            />
                        )}
                        <div className={styles.headline}>{this.props.headerText}</div>
                        <div className={styles.subheadline}>{this.props.subheaderText}</div>
                    </div>
                    <Formik
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            email: '',
                            password: '',
                            password_confirm: '',
                            captcha: false,
                            grecaptcha_response: '',
                        }}
                        validate={this.validateForm}
                        onSubmit={this.handleSubmit}
                    >
                        {({ errors, status, touched, isSubmitting , values}: FormikProps<SignupModalFormData>) => (
                            <Form>
                                <div>
                                    <div>
                                        <div className={styles.nameWrapper}>
                                            {this.renderFormField(
                                                'first_name',
                                                'text',
                                                'First Name',
                                                !!touched.first_name && !!errors.first_name,
                                                errors.first_name,
                                                'First name'
                                            )}
                                            {this.renderFormField(
                                                'last_name',
                                                'text',
                                                'Last Name',
                                                !!touched.last_name && !!errors.last_name,
                                                errors.last_name,
                                                'Last name'
                                            )}
                                        </div>

                                        {this.renderFormField('email', 'email', 'Email', !!touched.email && !!errors.email, errors.email, 'Email')}

                                        {this.renderFormField(
                                            'password',
                                            'password',
                                            'Password',
                                            !!touched.password && !!errors.password,
                                            errors.password,
                                            'Password'
                                        )}

                                        {this.renderFormField(
                                            'password_confirm',
                                            'password',
                                            'Confirm Password',
                                            !!touched.password_confirm && !!errors.password_confirm,
                                            errors.password_confirm,
                                            'Confirm password'
                                        )}
                                        
                                        <Field type='checkbox' hidden={true} id='signup-form-hidden-input' name='captcha' aria-label='Captcha'/>

                                        {status && <div className={styles.formError}>{status}</div>}
                                    </div>
                                </div>
                                {/* This should live in the body to allow the modal to scroll */}
                                <div className={styles.footer}>

                                <div id='signup_form_recaptcha' className={styles.recaptchaResize}/>

                                <div className={styles.recaptchaError}>
                                    {(isSubmitting && !values.captcha) ? errors.captcha = 'Captcha verification failed' : errors.captcha}
                                </div>

                                    <button
                                        type='submit'
                                        className={cls(styles.signinButton, 'nx-button', 'nx-button--primary')}
                                        disabled={isSubmitting}
                                    >
                                        Sign Up
                                    </button>
                                    <div className={styles.footerText}>
                                        By signing up, you accept our{'\u00a0'}
                                        <a className={styles.link} href='/terms-of-service/' target='_blank'>
                                            Terms of Service
                                        </a>
                                        {'\u00a0'}and{'\u00a0'}
                                        <a className={styles.link} href='/privacy/' target='_blank'>
                                            Privacy Policy
                                        </a>
                                        .
                                    </div>
                                    <div className={styles.footerText}>
                                        <a className={styles.link} href={YOUR_PRIVACY_CHOICES_URL} target='_blank'>
                                            {YOUR_PRIVACY_CHOICES}
                                        </a>
                                    </div>
                                    <div className={styles.clearfix}>
                                        <div className={styles.accessText}>
                                            <div>Do you represent a venue?</div>
                                            <a href='/vendor-signup/' className={styles.accessLink}>
                                                Request access
                                            </a>
                                        </div>
                                        <div className={styles.memberText}>
                                            <div>Already a member?</div>
                                            <a href='#' onClick={this.handleAuthClick}>
                                                Sign In
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </PageOverlay>
        );
    }

    private renderFormField(fieldName: string, type: string, placeholder: string, hasError: boolean, errorMsg: string | undefined, label:string) {
        return (
            <div className={cls(styles.inputWrapper, { [styles.inputWrapperError]: hasError })}>
                <Field
                    className={cls(styles.input, { [styles.inputError]: hasError })}
                    name={fieldName}
                    type={type}
                    placeholder={placeholder}
                    aria-label={label}
                />
                {hasError && <div className={styles.fieldError}>{errorMsg}</div>}
            </div>
        );
    }

    private validateForm(values: SignupModalFormData) {
        let errors: Partial<SignupModalFormData> = {};
        if (!values.email) {
            errors.email = 'Please enter an email';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Please enter a valid email address';
        }
        if (!values.password) {
            errors.password = 'Please enter a password';
        }
        if (!values.first_name) {
            errors.first_name = 'Please enter a first name';
        }
        if (!values.last_name) {
            errors.last_name = 'Please enter a last name';
        }
        if (!values.password_confirm) {
            errors.password_confirm = 'Please enter a password confirmation';
        }
        if (!!values.password && !!values.password_confirm && values.password !== values.password_confirm) {
            errors.password_confirm = 'Your passwords must match';
        }
        return errors;
    }

    private handleSubmit(values: SignupModalFormData, actions: FormikActions<SignupModalFormData>) {
        //@ts-ignore
        values.grecaptcha_response= grecaptcha.getResponse(LocalStorage.getItem(StorableDataKeys.RECAPTCHA_WIDGET_ID_SIGNUP_INQUIRY_FORM));

        actions.setSubmitting(true);
        return this.context
            .createNewAccount(values)
            .then(() => {
                this.props.onCreateAccountSuccess && this.props.onCreateAccountSuccess();
                this.context
                    .loadUserData()
                    .then(() => {
                        this.context.closeSignupModal();
                        this.context.setIsForceAuth(false);
                    })
                    .catch(() => {
                        actions.setStatus(genericSignupError);
                    })
                    .then(() => {
                        actions.setSubmitting(false);
                        values.captcha=false;
                        //@ts-ignore
                        grecaptcha.reset(LocalStorage.getItem(StorableDataKeys.RECAPTCHA_WIDGET_ID_SIGNUP_INQUIRY_FORM));
                    });
            })
            .catch((err) => {
                // Map the API keys to form data keys
                const mapErrorKeys = (errs: Auth.CreateAccountRequest) => {
                    return {
                        first_name: errs.first_name,
                        last_name: errs.last_name,
                        email: errs.email,
                        password: errs.password1,
                        password_confirm: errs.password2,
                        captcha: errs.grecaptcha_response,
                    };
                };

                if (err && err.detail && err.detail.content) {
                    // Process response from server
                    // actions.setError(err.detail.content.detail);
                    actions.setErrors(mapErrorKeys(err.detail.content.errors));
                } else {
                    // Unknown error format
                    actions.setStatus(genericSignupError);
                }

                actions.setSubmitting(false);
                values.captcha=false;
                //@ts-ignore
                grecaptcha.reset(LocalStorage.getItem(StorableDataKeys.RECAPTCHA_WIDGET_ID_SIGNUP_INQUIRY_FORM));
            });
    }

    private handleAuthClick(e: React.SyntheticEvent<HTMLAnchorElement>) {
        e.preventDefault();
        this.context.openAuthModal();
    }
}
