import React, { useCallback, useEffect } from 'react';
import cn from 'classnames';
import styles from './GuestRooms.module.scss';
import { HotelCard } from '../../hotels/HotelCard';
import { AnalyticsEvent, logEvent } from '@weddingspot/ws-analytics';
import { RFPModal } from './RFPModal';
import { VenueData } from '../../appointments/RFPHotelForm';

interface GuestRoomsDefaultProps {
    exploreHotelsUrl?: string;
    handleExploreHotelsClick?: () => void;
    // tslint:disable-next-line:no-any
    hotelsList?: any[];
    // tslint:disable-next-line:no-any
    hotelsProfiles?: any[];
    isProfileV3?: boolean;
}

const source = 'venue profile page';

const GuestRoomsDefault = (
    {
        exploreHotelsUrl,
        hotelsList,
        hotelsProfiles,
        isProfileV3
    }: GuestRoomsDefaultProps) => {

    const [submittedCard, setSubmittedCard] = React.useState<VenueData | null>(null);

    const version = isProfileV3 ? 'v3' : 'v2';

    const handleExploreHotelsClick = () => {
        // hotels + redirect case
        if (hotelsList?.length && hotelsProfiles?.length) {
            logEvent(
                AnalyticsEvent.EVNTM_CLICKED_EXPLORE_HOTELS_CTA_FROM_HOTELS_RECOMMENDATIONS,
                {
                    source,
                    version
                }
            );
        // just redirect case
        } else {
            logEvent(
                AnalyticsEvent.EVNTM_CLICKED_EXPLORE_HOTELS_CTA_ON_PROFILE,
                {
                    source: 'CTA only hotels redirect',
                    version
                }
            );
        }
    };

    const amplitudeQuickViewHandler = useCallback(
        (position) => {
            logEvent(AnalyticsEvent.EVTNM_CLICKED_QUICKVIEW_CTA, {
                hotelPosition: position,
                source,
                version
            });
        },
        [version]
    );

    useEffect(
        () => {
            if (hotelsList?.length) {
                logEvent(
                    AnalyticsEvent.EVTNM_SHOWN_GUEST_ROOM_RECOMMENDATIONS,
                    {
                        source,
                        version
                    });
            }
        },
        [hotelsList, version]);

    return (
        <>
        {hotelsList?.length && hotelsProfiles?.length ? (
            <>
            <h2>Guest Rooms</h2>
            <div className={cn(styles.wrapper, styles.emptyRoomBlocsContainer)}>
                <div>Looking for the best hotels near your venue?</div>
                <div className={styles.copy}>
                    Let's help you find the best sleeping accommodations for your guests near the venue at the best prices!
                </div>
                {hotelsList?.length && hotelsProfiles?.length
                    ? hotelsList.map((v, i) => {
                          return (
                              <HotelCard
                                  onQuickView={() => {}}
                                  key={i}
                                  id={v.id}
                                  profileData={hotelsProfiles.find((p) => p.basicProfile.id === v.id)}
                                  name={v.name}
                                  hotelType={v.type}
                                  state={v.stateProvinceCode}
                                  city={v.city}
                                  guestRooms={v.totalSleepingRoom}
                                  rating={v.preferredRating}
                                  imageUrl={v.heroImage}
                                  focused={false}
                                  fromVenuePageProps={{
                                      onClickQuickView: amplitudeQuickViewHandler.bind(null, i + 1),
                                      onSubmit: () => {
                                          setSubmittedCard(v);
                                          logEvent(AnalyticsEvent.EVTNM_HOTEL_SELECTED, { source });
                                      },
                                  }}
                              />
                          );
                      })
                    : null}
                <div className={cn(styles.exploreHotelsBtnWrapper)}>
                    <a
                        href={exploreHotelsUrl}
                        className={cn(styles.exploreHotelsBtn)}
                        target='_blank'
                        onClick={handleExploreHotelsClick}
                    >
                        {`Explore ${hotelsList?.length && hotelsProfiles?.length ? 'More ' : ''}Hotels`}
                    </a>
                </div>
            </div>
            <RFPModal rfpModalShowing={Boolean(submittedCard)} setRfpModalShowing={setSubmittedCard} submittedCard={submittedCard} />
            </>
        ) : null}
        </>
    );
};
export default GuestRoomsDefault;
