// this is lame, but ok so far

import * as React from 'react';
import { FormEvent } from 'react';
import { LOCATIONS_LIST_DATA } from './locationsListData';

import styles from './LocationsListingMobile.module.scss';

export default class LocationsListing extends React.Component {
    private onChange(event: FormEvent<HTMLSelectElement>) {
        if (!!event.currentTarget.value) {
            const selectedMarket = event.currentTarget.value.split(' ').join('-');
            window.location.href = '/wedding-venues/' + selectedMarket + '/';
        }
    }

    public render() {
        return (
            <div className={styles.wrapper}>
                <div>
                    Locations <i className='icon-hb-arrowdown' />
                </div>
                <select className={styles.select} onChange={this.onChange}>
                    {LOCATIONS_LIST_DATA.map((item, idx) => {
                        return (
                            <option value={item[0]} key={idx}>
                                {item[1]}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }
}
