import * as React from 'react';
import cn from 'classnames';
import XIcon from '@cvent/carina/components/Icon/X';

import { ToastDataInterface } from './ToastProvider';
import { formatCventImage } from '../../utils/imageUtils';

import styles from './Toast.module.scss';

interface ToastComponent {
    data: ToastDataInterface;
    onClose: () => void;
}

const Toast = ({ data, onClose }: ToastComponent) => {
    const { title, text, action, type, imageUrl, Icon } = data;

    const onActionClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        if (action) {
            onClose();
            action.onClick();
        }
    };

    return (
        <div
            className={cn(styles.container, {
                [styles.success]: type === 'success',
                [styles.danger]: type === 'danger',
            })}
        >
            <div
                className={cn(styles.imageContainer, {
                    [styles.noIcon]: !Icon,
                })}
            >
                {Icon || null}
                {imageUrl && <img src={formatCventImage({ src: imageUrl })} />}
            </div>
            <div className={styles.textContainer}>
                {title && <h2 className={styles.title}>{title}</h2>}
                {(text || action) && (
                    <p className={styles.text}>
                        {text}
                        {action && (
                            <>
                                {' '}
                                - <a onClick={onActionClick}>{action.text}</a>{' '}
                            </>
                        )}
                    </p>
                )}
            </div>
            <div className={styles.close} onClick={onClose}>
                <XIcon />
            </div>
        </div>
    );
};

export default Toast;
