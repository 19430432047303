// import { createInstance, AuthScheme, ApiClientConfig } from '@weddingspot/ws-api-client';

/**
 * HOGAN_CONFIG values comes from the front-end service, e.g. ws-next/weddingspot-core-react(ws-render), that consumes this package.
 * 
 * ws-next has DefinePlugin configured in next.config.js, that simply replaces all occurence of HOGAN_CONFIG_FROM_FRONT_END_SERVICE with
 * actual hogan values, generated during build & deployment stage.
 * 
 * weddingspot-core-react (ws-render) has DefinePlugin configured packages/weddingspot-core-react/config/webpack/webpack.config.plugins.js
 * It replaces all occurence of HOGAN_CONFIG_FROM_FRONT_END_SERVICE with actual hogan values, generated at build deployment stage.
 * 
 * TOD0: We can use this HOGAN_CONFIG variable and can fetch the hoganized value for below hard-coded variables https://jira.cvent.com/browse/WS-3604
 * 
 * Refer https://webpack.js.org/plugins/define-plugin/ 
 */

// @ts-ignore
const HOGAN_CONFIG : HoganConfig = HOGAN_CONFIG_FROM_FRONT_END_SERVICE

export const IS_DEV_ENV = HOGAN_CONFIG.env === 'local';
export type IS_DEV_ENV = typeof IS_DEV_ENV;

// @ts-ignore
export const IS_ALPHA = HOGAN_CONFIG.env === 'ap50';
export type IS_ALPHA = typeof IS_ALPHA;

// @ts-ignore
export const IS_STAGING = HOGAN_CONFIG.env === 'sg50';
export type IS_STAGING = typeof IS_STAGING;

export const IS_PROD_ENV = !(IS_DEV_ENV || IS_STAGING || IS_ALPHA);
export type IS_PROD_ENV = typeof IS_PROD_ENV;

export const SERVER_ADDRESS = IS_DEV_ENV ? 'http://local.wedding-spot.com:9000' : '';
export type SERVER_ADDRESS = typeof SERVER_ADDRESS;

// let config = {
//     apihost: 'wedding-spot.com',
//     apiport: 443,
//     apiprotocol: 'https',
//     apibaseurl: 'api/v1/',
//     authenticationScheme: AuthScheme.CSRF,
//     oauthClientId: 'tJXMnmpuJEDHDYPMn7OqtND1YMm75McP',
// };

// if (IS_DEV_ENV) {
//     config.apihost = 'local.wedding-spot.com';
//     config.apiport = 9000;
//     config.apiprotocol = 'http';
// } else if (IS_STAGING) {
//     config.apihost = 'ecstest.wedding-spot.com';
//     config.apiport = 443;
//     config.apiprotocol = 'https';
// } else if (IS_ALPHA) {
//     config.apihost = 'ecstestap50.wedding-spot.com';
//     config.apiport = 443;
//     config.apiprotocol = 'https';
// }

// export const wsApiClient = createInstance(config);

// export const initApiClient = (conf?: Partial<ApiClientConfig>) => {
//     let _conf = {};
//     if (!!conf) {
//         Object.assign(_conf, config, conf);
//     }

//     Object.assign(wsApiClient.config, _conf);
// };

export const SRFP_ACCESS_KEY = HOGAN_CONFIG.srfp.access_key;

export const SRFP_BASE_URL = HOGAN_CONFIG.srfp.base_url;

export const RECAPTCHA_SITE_KEY = HOGAN_CONFIG.ws_captcha.site_key;

export const CVENT_SALESFORCE_URL = HOGAN_CONFIG.cvent_salesforce.url;

export interface HoganConfig {
    env: string,
    cdn: string;
    amplitude: {
        api_key: string;
    };
    google_analytics: {
        id: string;
    };
    google_maps: {
        api_key: string;
    };
    google_places: {
        api_key: string;
    };
    meta_robots: string;
    csn: {
        search_service: {
            base_url: string;
            env: string;
        };
        venue_profile_service: {
            base_url: string;
            env: string;
        };
        rfp_service: {
            base_url: string;
            env: string;
        };
        gdpr_service: {
            base_url: string;
        };
    };
    sentry: {
        dsn: string;
    };
    ws_api_client: {
        base_url: string,
    };
    datadog: {
        applicationId: string
        clientToken: string
    };
    srfp: {
        base_url: string;
        access_key: string;
    };
    ws_captcha: {
        site_key: string;
    };
    cvent_salesforce: {
        url: string;
    };
}
