import * as React from 'react';
import { CartServiceContext, CartType, useCart, CartItem } from './service';
import { Appointments } from '@weddingspot/ws-api-client';
import { useState } from 'react';

export interface CartComponentContext extends CartServiceContext, OverridableCartContext {
    cartOpen: boolean;
    setCartOpen: (open: boolean) => void;
    onOpenCartTouch: () => void;
}

export const ShoppingCartContext = React.createContext<CartComponentContext>({
    itemsMax: 0,
    cartOpen: false,
    cartOverflowed: false,
    setCartOpen: () => {},
    version: -1,
    items: [],
    cartReady: false,
    getItem: () => null,
    addItem: () => Promise.reject('Context improperly initialized'),
    removeItem: () => Promise.reject('Context improperly initialized'),
    resetCart: () => Promise.reject('Context improperly initialized'),
    customButtonStyle: {},
    setCustomButtonStyle: (val) => {},
    onOpenCartTouch: () => null,
});

interface CartHookArgs {
    itemsMax: number;
    cartType: CartType;
    logError?: () => void;
}

interface OverridableCartContext {
    onCartOpen?: (sourcePage?: string) => void;
    onSubmitCTAClick?: (items: CartItem[], sourcePage?: string) => void;
    onSuccessfulOutreach?: (request: Appointments.MassInquiryRequestParams, sourcePage?: string) => void;
    onItemRemoved?: (itemId: number | string) => void;
    onExperienceClose?: () => void; // method that raise on shopping cart "close" event
    onExperienceRendered?: () => void; // method that raise on shopping cart "render" event
    onExperienceNext?: () => void; // method that raise on shopping cart "next slide" event
    customButtonStyle?: object;
    setCustomButtonStyle?: (val: object) => void;
}

type ShoppingCartContextProviderProps = CartHookArgs & OverridableCartContext;

export const ShoppingCartContextProvider = <P extends ShoppingCartContextProviderProps>(props: React.PropsWithChildren<P>) => {
    const {
        // CartHookArgs
        cartType,
        itemsMax,
        logError,

        // OverridableCartContext,
        ...overrideProps
    } = props;

    const [cartOpen, setCartOpen] = React.useState<boolean>(false);
    const [timeoutId, setTimeoutId] = React.useState<number | null>(null);
    const openCart = () => {
        setCartOpen(true);
    };

    const [customButtonStyle, setCustomButtonStyle] = useState({});
    const onOpenCartTouch = () => {
        if (timeoutId) {
            clearInterval(timeoutId);
        }
    };
    const cartProps = useCart(cartType, itemsMax, openCart, cartOpen, logError);
    const addItem = (value: CartItem) => {
        if (cartProps.items.length < itemsMax) {
            setCartOpen(true);
            const timeout: number = setTimeout(() => {
                setCartOpen(false);
            }, 5000) as unknown as number;
            setTimeoutId(timeout);
        }
        return cartProps.addItem(value);
    };

    return (
        <ShoppingCartContext.Provider value={{ ...cartProps, cartOpen, setCartOpen, ...overrideProps, customButtonStyle, setCustomButtonStyle, onOpenCartTouch, addItem }}>
            {props.children}
        </ShoppingCartContext.Provider>
    );
};
