import * as React from 'react';

import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import ReactBnbGallery from 'react-bnb-gallery';
import 'react-bnb-gallery/dist/style.css';

import cn from 'classnames';

import { AnalyticsEvent, logEvent } from '@weddingspot/ws-analytics';

import { CSNDiamondLevel, CSNGuestRoom, CSNVenueProfile, CSNVenueProfileImage } from '@weddingspot/ws-api-client';

interface Props {
    csnVenueData?: GuestRoomsData;
    onSectionExpanded?: () => void;
    onClick: () => void;
    isProfileV3?: boolean;
    exploreHotelsUrl?: string;
    // tslint:disable-next-line:no-any
    hotelsList?: any[];
    // tslint:disable-next-line:no-any
    hotelsProfiles?: any[];
    showRecommendations?: boolean;
}

export interface GuestRoomsData extends CSNVenueProfile {}

import styles from './GuestRooms.module.scss';
import GuestRoomsDefault from './GuestRoomsDefault';

const GuestRooms = (props: Props) => {
    const [showSection, setShowSection] = useState(false);
    const [venueData, setVenueData] = useState<GuestRoomsData>();
    const [isOpen, setIsOpen] = useState(false);
    const [activePhotoIndex, setActivePhotoIndex] = useState(0);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    useEffect(() => {
        inView && logEvent(AnalyticsEvent.EVTNM_VISITED_GUEST_ROOMS, { Source: 'Wedding venue profile' });
    }, [inView]);

    useEffect(() => {
        if (
            props.csnVenueData?.guestRoom &&
            props.csnVenueData?.images?.length &&
            props.csnVenueData?.basicProfile.diamondLevel !== CSNDiamondLevel.BASIC &&
            props.csnVenueData?.basicProfile.diamondLevel !== CSNDiamondLevel.HALF_DIAMOND

        ) {
            setVenueData(props.csnVenueData);
            setShowSection(true);
        }
    }, [props.csnVenueData]);

    const renderOneInsight = (title: string, data?: string | number) => {
        return data && data !== 0 ? (
            <div className={styles.roomInsight}>
                <div className={styles.insightTitle}>{title}</div>
                <div className={styles.insightData}>{data}</div>
            </div>
        ) : null;
    };

    const renderRoomsInfoSection = (roomsData: CSNGuestRoom) => {
        if (!roomsData.roomDetails) {
            return;
        }

        const double = roomsData.roomDetails.find((room) => room.type === 'DOUBLE');
        const single = roomsData.roomDetails.find((room) => room.type === 'SINGLE');
        const suites = roomsData.roomDetails.find((room) => room.type === 'SUITE');
        const singleRate =
            single &&
            single.count &&
            single.minRate &&
            single.maxRate &&
            (Math.round(single.minRate) === Math.round(single.maxRate)
                ? `$${Math.round(single.minRate)}`
                : `$${Math.round(single.minRate)}-$${Math.round(single.maxRate)}`);

        return (
            <div className={styles.roomsInfo}>
                {renderOneInsight('Total Guest Rooms', roomsData.totalRoomCount)}
                {renderOneInsight('Double (2 beds)', double?.count)}
                {renderOneInsight('Single (1 bed)', single?.count)}
                {renderOneInsight('Suites', suites?.count)}
                {renderOneInsight('Single (1 bed rate)', singleRate)}
            </div>
        );
    };

    const renderImagesSection = (images: CSNVenueProfileImage[]) => {
        const allPhotos = images.filter((image) => {
            return image.group === 'SLEEPING_ROOM' && image.imageURL?.large;
        });

        return allPhotos.length ? (
            <>
                <div className={cn(styles.imageRow, { [styles.imageRowNext]: props.isProfileV3 })}>
                    {allPhotos.slice(0, 3).map((image, idx) => {
                        return (
                            <div
                                key={idx}
                                className={styles.imageContainer}
                                onClick={() => {
                                    setIsOpen(true);
                                    setActivePhotoIndex(idx);
                                }}
                            >
                                <img
                                    // using large as we don't care about load speed as it is happening
                                    // after page load and this section is collapsed
                                    // on the other hand, same size is required for gallery
                                    src={image.imageURL.large}
                                    alt={image.name}
                                />
                                {idx === 2 && allPhotos.length > 3 && (
                                    <div className={styles.otherImagesOverlay}>+{allPhotos.length - 3}</div>
                                )}
                            </div>
                        );
                    })}
                </div>
                <ReactBnbGallery
                    show={isOpen}
                    activePhotoIndex={activePhotoIndex}
                    photos={allPhotos.map((image) => ({
                        photo: image.imageURL.large,
                        caption: image.name,
                        subcaption: image.description,
                    }))}
                    onClose={() => setIsOpen(false)}
                />
            </>
        ) : null;
    };

    const renderGuestRoomsSection = () => (
        <div ref={ref}>
            <h2>Guest Rooms</h2>
            <div className={styles.wrapper}>
                <div className={styles.copy}>
                    Make your life easier, save time and money, and have all of your guests stay in one place with room blocks.
                    <br />
                    Request a proposal to get the best rates.
                </div>
                {venueData?.guestRoom && renderRoomsInfoSection(venueData.guestRoom)}
                {venueData?.basicProfile?.offeringStatus === 'APPROVED' && (
                    <button
                        className={cn('nx-button', styles.ctaButton, { [styles.ctaNextButton]: props.isProfileV3 })}
                        onClick={() => {
                            props.onClick();
                            logEvent(AnalyticsEvent.EVTNM_CLICKED_REQUEST_RFP, { Source: 'Wedding venue profile' });
                        }}
                    >
                        REQUEST SLEEPING ROOM PROPOSAL
                    </button>
                )}
                {venueData?.images?.length && renderImagesSection(venueData.images)}
            </div>
            <div className='VenuePage--additional-details-separator' />
        </div>
    );

    return (
        <div className={styles.container}>
            {showSection ? (
                renderGuestRoomsSection()
            ) : (
                <GuestRoomsDefault
                    exploreHotelsUrl={props.exploreHotelsUrl}
                    hotelsList={props.hotelsList}
                    hotelsProfiles={props.hotelsProfiles}
                    isProfileV3={props.isProfileV3}
                />
            )}
        </div>
    );
};

export default GuestRooms;
