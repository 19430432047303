import * as React from 'react';
import { mockGetCartUnauth } from './mocks';
import { RatingProps } from '../csn/Rating';
import { ToastContext } from '../toast/ToastProvider';
import LockIcon from '@cvent/carina/components/Icon/Lock';
// import { FeatureFlagContext, FLAG_SHOPPING_CART_FOR_SRP } from '../../providers/flag-context';
import { HeaderContext } from '../header/HeaderContext';
import LocalStorage, { StorableDataKeys } from '../../utils/storage';

/**
 * Describes where the cart data is persisted, NONE means the cart data is
 * not persisted and new cart data is initialized every time the react app loads
 */
export enum CartStorage {
    NONE = 0,
    API,
    LOCALSTORAGE,
}

/**
 * Describes the type of shopping cart
 */
export enum CartType {
    HOTELS = 1,
    VENUES = 2,
}

/**
 * Data for a single cart item
 */
export interface CartItem {
    id: string | number;
    name: string;
    location: string;
    imageUrl?: string;
    rating?: RatingProps;
    isRecommendation?: boolean;
    lastUpdated: number;
    market?: string;
    isDestination?:boolean;
    address?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    srpView?: string | null;
    contractPackage?: string;
}

/**
 * Cart data as stored in the storage backend
 */
export interface CartData {
    items: CartItem[];
    lastUpdated?: number;
    recommendationsSeen?: boolean;
}

/**
 * Cart interface returned by the useCart hook
 */
export interface CartServiceState {
    itemsMax: number;
    version: number;
    items: CartItem[];
    cartReady: boolean;
    cartStorage?: CartStorage;
    cartOverflowed: boolean;
    recommendationsSeen?: boolean;
}

export enum CartOperationError {
    UNKNOWN = 0,
    CART_FULL,
    CART_NOT_READY,
}

export interface CartOperationResult {
    success: boolean;
    errorType?: CartOperationError;
}

/**
 * Content + manipulation APIs
 */
export interface CartServiceContext extends CartServiceState {
    getItem: (i: string | number) => CartItem | null;
    addItem: (i: CartItem) => Promise<CartOperationResult>;
    removeItem: (i: string | number) => Promise<CartOperationResult>;
    resetCart: () => Promise<CartOperationResult>;
}

// Mapping of CartType: localStorage key
const CART_KEY_MAP = {
    [CartType.HOTELS]: 'HOTELS_CART',
    [CartType.VENUES]: 'VENUES_CART',
};

/**
 * Attempts to read the specified cart data from localstorage and deserizlie it.
 * If no data exists for the given cart type, initializes it, stores it, and returns the
 * deserialized copy
 * @param cartType cart type
 */
const _readOrInitCartDataFromLocalstorage = (cartType: CartType) => {
    const CART_KEY = CART_KEY_MAP[cartType];
    let cartDataSerialized = window.localStorage[CART_KEY];
    let cartData;
    if (!cartDataSerialized) {
        cartData = { items: [], recommendationsSeen: false };
        window.localStorage[CART_KEY] = JSON.stringify(cartData);
    } else {
        cartData = JSON.parse(cartDataSerialized) as CartData;
    }
    return cartData;
};

/**
 * Serializes and writes the given CartData object to localStorage
 * @param cartType cart type
 * @param cartData cart data
 */
const _writeCartDataToLocalStorage = (cartType: CartType, cartData: CartData) => {
    const CART_KEY = CART_KEY_MAP[cartType];
    window.localStorage[CART_KEY] = JSON.stringify(cartData);
};

/**
 * Attempts to add cart item to the specified storage backend. Returns a promise that resolves
 * to the entire CartData objects. If storage === CartStorage.NONE, the promise will resovle with null
 * return value because there is no storage operation to be performed. On storage failure, the promise
 * will reject with an error message
 * @param storage storage type
 * @param cartType cart type
 * @param item item data
 */
const _addCartItem = (storage: CartStorage, cartType: CartType, item: CartItem, logError?: () => void): Promise<CartData | null> => {
    if (storage === CartStorage.API) {
        return Promise.reject('API Storage not implemented');
    } else if (storage === CartStorage.LOCALSTORAGE) {
        try {
            // Fetch or initialize cart data
            const cartData = _readOrInitCartDataFromLocalstorage(cartType);

            // Update or insert item
            const items = cartData.items;
            const existingItemItx = items.findIndex((i) => i.id === item.id);
            if (existingItemItx !== -1) {
                items[existingItemItx] = item;
            } else {
                items.push(item);
            }
            cartData.items = items;
            cartData.lastUpdated = new Date().getTime();
            cartData.recommendationsSeen = cartData.recommendationsSeen || item.isRecommendation;
            // Persist cart data
            _writeCartDataToLocalStorage(cartType, cartData);
            return Promise.resolve(cartData);
        } catch (e) {
            if (logError) {
                logError();
            }
            // Localstorage errors can happen if there are capacity or availability issues
            return Promise.resolve(null);
        }
    } else {
        return Promise.resolve(null);
    }
};

/**
 * Attempts to remove cart item to the specified storage backend. Returns a promise that resolves
 * to the entire CartData objects. If storage === CartStorage.NONE, the promise will resovle with null
 * return value because there is no storage operation to be performed. On storage failure, the promise
 * will reject with an error message
 * @param storage storage type
 * @param cartType cart type
 * @param itemId ID of item to be removed
 */
const _removeCartItem = (
    storage: CartStorage,
    cartType: CartType,
    itemId: number | string,
    logError?: () => void
): Promise<CartData | null> => {
    if (storage === CartStorage.API) {
        return Promise.reject('API Storage not implemented');
    } else if (storage === CartStorage.LOCALSTORAGE) {
        try {
            // Fetch or initialize cart data
            const cartData = _readOrInitCartDataFromLocalstorage(cartType);

            // Remove item if present
            const items = cartData.items;
            const existingItemItx = items.findIndex((i) => i.id === itemId);
            if (existingItemItx !== -1) {
                items.splice(existingItemItx, 1);
            }
            cartData.items = items;
            cartData.lastUpdated = new Date().getTime();

            // Persist cart data
            _writeCartDataToLocalStorage(cartType, cartData);
            return Promise.resolve(cartData);
        } catch {
            if (logError) {
                logError();
            }
            // Localstorage errors can happen if there are capacity or availability issues
            return Promise.resolve(null);
        }
    } else {
        return Promise.resolve(null);
    }
};

/**
 * Reset cart back to original state (empty w/ no recommendations seen). This should be used
 * to reset the cart after a checkout has occurred
 *
 * @param storage cart storage
 * @param cartType cart type
 */
const _resetCart = (storage: CartStorage, cartType: CartType, logError?: () => void): Promise<CartData | null> => {
    if (storage === CartStorage.API) {
        return Promise.reject('API Storage not implemented');
    } else if (storage === CartStorage.LOCALSTORAGE) {
        try {
            // Fetch or initialize cart data
            const cartData = _readOrInitCartDataFromLocalstorage(cartType);
            cartData.items = [];
            cartData.lastUpdated = new Date().getTime();
            cartData.recommendationsSeen = false;

            // Persist cart data
            _writeCartDataToLocalStorage(cartType, cartData);
            return Promise.resolve(cartData);
        } catch {
            if (logError) {
                logError();
            }
            // Localstorage errors can happen if there are capacity or availability issues
            return Promise.resolve(null);
        }
    } else {
        return Promise.resolve(null);
    }
};

/**
 * Hook for initializing cart state, returns an object with the cart state and API for
 * reading and manipulating cart data
 * @param cartType cart type
 * @param itemsMax
 * @param setCartOpen
 * @param logError
 */
export const useCart = (
    cartType: CartType,
    itemsMax: number,
    setCartOpen: () => void,
    cartOpen: boolean,
    logError?: () => void
): CartServiceContext => {
    const [cartItems, setCartItems] = React.useState<CartItem[]>([]);
    const [recommendationsSeen, setRecommendationsSeen] = React.useState<boolean | undefined>();
    const [cartStorage, setCartStorage] = React.useState<CartStorage | undefined>();
    const [cartReady, setCartReady] = React.useState<boolean>(false);
    const [cartOverflowed, setCartOverflowed] = React.useState<boolean>(false);

    const { fire } = React.useContext(ToastContext);
    const { profileVersion } = React.useContext(HeaderContext);
    const showShoppingCart = profileVersion === 'v3';
    const shoppingCartExperienceShowed = LocalStorage.getItem(StorableDataKeys.SRP_SHOPPING_CART_EXPERIENCE_SHOPPING_CART_SHOWED);

    // Go to network to see if we can get cart data
    React.useEffect(() => {
        mockGetCartUnauth()
            .then(() => {
                throw 'API initialization not implemented!';
            })
            .catch(() => {
                // Can't go to network, see if we can find a copy in the localstorage
                try {
                    setCartStorage(CartStorage.LOCALSTORAGE);
                    const cartData = _readOrInitCartDataFromLocalstorage(cartType);
                    setCartItems(cartData.items);
                    setCartReady(true);
                    setRecommendationsSeen(cartData.recommendationsSeen);
                } catch (e) {
                    if (logError) {
                        logError();
                    }
                    // Nothing available
                    setCartStorage(CartStorage.NONE);
                    setCartReady(true);
                    setRecommendationsSeen(false);
                }
            });
    }, []);

    React.useEffect(() => {
        const storageHandler = (e: StorageEvent) => {
            if ((e.key as string) === 'VENUES_CART' && e.newValue) {
                const newValue = JSON.parse(e.newValue) as CartData;
                setCartItems(newValue.items);
            }
        };
        window.addEventListener('storage', storageHandler);
        return () => {
            window.removeEventListener('storage', storageHandler);
        };
    }, []);

    const getItem = (i: string | number) => {
        if (!cartReady || !cartStorage) {
            throw 'Cart not ready...';
        }

        return cartItems.find((item) => item.id === i) || null;
    };

    const addItem = (i: CartItem) => {
        if (!cartReady || !cartStorage) {
            return Promise.resolve({ success: false, errorType: CartOperationError.CART_NOT_READY });
        }

        if (cartItems.length >= itemsMax) {
            setCartOverflowed(true);
            fire({
                title: `Can't add more than ${itemsMax} hotels.`,
                type: 'default',
                text: `Can't add more than ${itemsMax} venues at once`,
                action: {
                    text: 'Contact these venues',
                    onClick: () => {
                        setCartOpen();
                        if (window.innerWidth > 768) {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }
                    },
                },
                Icon: <LockIcon />,
            });
            return Promise.resolve({ success: false, errorType: CartOperationError.CART_FULL });
        }

        return _addCartItem(cartStorage, cartType, i, logError).then((res: CartData | null) => {
            if (res === null) {
                // React state only
                setCartStorage(CartStorage.NONE);
                setCartItems([...cartItems, i]);
                setRecommendationsSeen(recommendationsSeen || i.isRecommendation);
            } else {
                setCartItems(res.items);
                setRecommendationsSeen(res.recommendationsSeen);
            }
            if (showShoppingCart && !shoppingCartExperienceShowed && cartItems.length === 0) {
                LocalStorage.setItem(StorableDataKeys.SRP_SHOPPING_CART_EXPERIENCE_SHOPPING_CART_SHOWED, true);
                setCartOpen();
            } else {
                // temporary hidden for new flow, maybe will be useful in future
                // fire({
                //     title: 'Venue Added!',
                //     type: 'success',
                //     text: i.name,
                //     action: {
                //         text: 'View',
                //         onClick: () => {
                //             setCartOpen();
                //         },
                //     },
                //     imageUrl: i.imageUrl,
                // });
            }

            return { success: true };
        });
    };

    const removeItem = (id: string | number) => {
        if (!cartReady || !cartStorage) {
            return Promise.resolve({ success: false, errorType: CartOperationError.CART_NOT_READY });
        }

        return _removeCartItem(cartStorage, cartType, id, logError).then((res: CartData | null) => {
            const currentCart = cartItems.find((item) => item.id === id);
            let newCartItems;
            if (res === null) {
                setCartStorage(CartStorage.NONE);
                newCartItems = cartItems.filter((item) => item.id !== id);
            } else {
                newCartItems = res.items;
            }

            setCartItems(newCartItems);
            if (newCartItems.length <= itemsMax) {
                setCartOverflowed(false);
            }

            if (currentCart && !cartOpen) {
                fire({
                    title: 'Venue Removed!',
                    type: 'danger',
                    text: currentCart.name,
                    action: {
                        text: 'Undo',
                        onClick: () => {
                            addItem(currentCart);
                        },
                    },
                    imageUrl: currentCart.imageUrl,
                });
            }

            return { success: true };
        });
    };

    const resetCart = () => {
        if (!cartReady || !cartStorage) {
            return Promise.resolve({ success: false, errorType: CartOperationError.CART_NOT_READY });
        }

        return _resetCart(cartStorage, cartType, logError).then((res: CartData | null) => {
            if (res === null) {
                setCartStorage(CartStorage.NONE);
                setCartItems([]);
            } else {
                setCartItems(res.items);
            }
            setCartOverflowed(false);
            return { success: true };
        });
    };

    return {
        version: 1,
        itemsMax,
        cartReady,
        cartStorage,
        recommendationsSeen,
        cartOverflowed,
        items: cartItems,
        getItem,
        addItem,
        removeItem,
        resetCart,
    };
};
