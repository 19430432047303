import * as React from 'react';
import { Venues } from '@weddingspot/ws-api-client';
import { RingsSpinner } from '../rings-spinner/RingsSpinner';
import { toUSD } from '../../utils/string';

export interface Props extends Venues.VenueRecommendation {
    position: number;
    onClick?: (position: number) => void;
}

interface State {
    imageDownloadFailed: boolean;
}

export default class VenueRecommendations extends React.Component<Props, State> {
    readonly state: State = {
        imageDownloadFailed: false,
    };

    constructor(props: Props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    static defaultProps: Partial<Props> = {
        name: '...',
        region: '...',
        main_image_url: '',
    };

    renderEstimateElement(value: number) {
        return (
            <div className='VenueRecommendationsHeroCell--content--text'>
                <b>Your Spot Estimate:</b>
                {` ${toUSD(value)}`}
            </div>
        );
    }

    renderBudgetElement(data?: Venues.VenueRecommendation) {
        if (data === undefined) {
            return;
        }

        const { budget_capacity, budget_min } = data;

        if (!budget_capacity || !budget_min) {
            return;
        }

        return (
            <div className='VenueRecommendationsHeroCell--content--text'>
                {budget_capacity === undefined || budget_min === undefined
                    ? ''
                    : `Starting from ${toUSD(budget_min)} for ${budget_capacity} guests`}
            </div>
        );
    }

    render() {
        const data = this.props;

        return (
            <div className='VenueRecommendationsHeroCell'>
                <a href={this.props.profile_url} target='_blank' onClick={this.onClick}>
                    <div className='VenueRecommendationsHeroCell--image-container'>
                        {this.state.imageDownloadFailed ? (
                            <div className='VenueRecommendationsHeroCell--image-placeholder-container'>
                                <RingsSpinner animate={false} />
                            </div>
                        ) : (
                            <img
                                src={this.props.main_image_url}
                                className='VenueRecommendationsHeroCell--image'
                                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                                    this.setState({ imageDownloadFailed: true });
                                }}
                            />
                        )}
                        <div className='VenueRecommendationsHeroCell--image-overlay'>
                            <div className='VenueRecommendationsHeroCell--venue-name'>{this.props.name}</div>
                            <div className='VenueRecommendationsHeroCell--region'>{this.props.region}</div>
                            <div className='VenueRecommendationsHeroCell--profile-link'>View Profile</div>
                        </div>
                    </div>
                </a>

                <div className='VenueRecommendationsHeroCell--content'>
                    {data !== undefined && data.estimate_total
                        ? this.renderEstimateElement(data.estimate_total)
                        : this.renderBudgetElement(data)}
                    <a
                        href={this.props.estimate_url ? this.props.estimate_url : this.props.profile_url}
                        target='_blank'
                        onClick={this.onClick}
                    >
                        <button
                            className={`VenueRecommendationsHeroCell--button
                                nx-button nx-button--primary nx-button--x-small`}
                        >
                            View Details
                        </button>
                    </a>
                </div>
            </div>
        );
    }

    private onClick() {
        !!this.props.onClick && this.props.onClick(this.props.position);
    }
}
