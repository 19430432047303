import * as React from 'react';
import { logEvent, AnalyticsEvent } from '@weddingspot/ws-analytics';
import { RFPContainer } from '../../RFP/container';
import { WSAppContext } from '../../../providers/ws-app';

export const RFPModal = (props: { rfpModalShowing: boolean; setRfpModalShowing: (showing: null) => void; submittedCard: any | null }) => {
    const { rfpModalShowing, setRfpModalShowing } = props;
    const { user } = React.useContext(WSAppContext);

    if (!rfpModalShowing) {
        return null;
    }

    const onSubmitRFP = () => {
        logEvent(AnalyticsEvent.EVTNM_CLICKED_SUBMIT_REQUEST_CTA, { source: 'venue profile page' });
    };

    const previewData = [
        {
            id: props.submittedCard.id as string,
            meta: {
                location: `${props.submittedCard.city}, ${props.submittedCard.stateProvinceCode}`,
                name: props.submittedCard.name,
                previewImg: props.submittedCard.heroImage?.original,
                rating: props.submittedCard.preferredRating,
            },
        },
    ];

    return (
        <>
            {rfpModalShowing && (
                <RFPContainer
                    show={rfpModalShowing}
                    onHide={() => setRfpModalShowing(null)}
                    csnVenueData={previewData}
                    defaultFormValue={user || undefined}
                    handleHotelsRecommendedForm={onSubmitRFP}
                    handleRoomCountSubmit={() => {}}
                    handleClickedSpecifyGuestRooms={() => {}}
                    leadSource='profile page'
                />
            )}
        </>
    );
};
