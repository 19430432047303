export const Z_INDEX = 20;

// Carina Model supports three formats [s,m,l]
export enum ModalFormat {
    SMALL = 's',
    MEDIUM = 'm',
    LARGE = 'l',
}

// This is the breakpoints at which Carina Model format changes from 'm' to 's'
export const SCREEN_WIDTH_BREAKPOINT = 600;

export const SESSION_ID_RENEWAL_TIMEOUT = 39 * 1000 * 60;
export const SESSION_EXTEND_WIDGET_DISPLAY_TIMEOUT = 5 * 1000 * 60;
export const MAX_IDLE_DURATION = 35 * 1000 * 60;

export enum UserAuthStatus {
    ANONYMOUS = 'anonymous',
    AUTHED = 'authed',
}