import cn from 'classnames';
import * as React from 'react';
import Link from '../link';
import { TypeaheadSearchResult } from '../typeahead/store';

import styles from './TypeaheadSearchResults.module.scss';

interface Props {
    locations: TypeaheadSearchResult[];
    styles: TypeaheadSearchResult[];
    venues: TypeaheadSearchResult[];
    keyboardSelectedIndex: null | number;
    inputValue?: string;
    onClick?: (link: string, inputValue?: string) => void;
    brandColorBackground?: boolean;
    disableVenueRedirection?: boolean;
}

export class TypeaheadSearchResults extends React.Component<Props> {
    handleSearchResultClick = (link: string, inputValue?: string) => {
        if (this.props.onClick) {
            this.props.onClick(link, inputValue);
        }
    };

    render() {
        const searchSubgroups = [
            { dataKey: 'locations' as const, label: 'Locations' },
            { dataKey: 'styles' as const, label: 'Styles' },
            { dataKey: 'venues' as const, label: 'Venues' },
        ];

        const lists = searchSubgroups.map(({ dataKey, label }, groupIndex: 0 | 1 | 2) => {
            if (!this.props[dataKey].length) {
                return null;
            }
            return (
                <div className={styles.listWrapper} key={dataKey}>
                    <h3 className={styles.headline}>{label}</h3>
                    {this.props[dataKey].map((s, i) => {
                        const isSelected = this.isSelected(groupIndex, i);
                        // TODO make query parse for s.link to not change filter params when navigate
                        return (
                            <Link
                                key={i}
                                url={this.props.disableVenueRedirection ? '#' : s.link}
                                className={cn({ [styles.linkSelected]: isSelected }, styles.link,{[styles.link__brandColorBackground]:this.props.brandColorBackground} )}
                                onClick={() => this.handleSearchResultClick(s.link, this.props.inputValue)}
                            >
                                {s.name}
                            </Link>
                        );
                    })}
                </div>
            );
        });
        return <div className={styles.className}>{lists}</div>;
    }

    private isSelected(category: 0 | 1 | 2, relIndex: number) {
        const currentIndex = this.props.keyboardSelectedIndex;

        if (category === 0) {
            return relIndex === currentIndex;
        } else if (category === 1) {
            return this.props.locations.length + relIndex === currentIndex;
        } else {
            return this.props.locations.length + this.props.styles.length + relIndex === currentIndex;
        }
    }
}
