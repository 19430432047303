import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Venues } from '@weddingspot/ws-api-client';

import * as types from './types';
import * as actions from './actions';
import Loading from '../LoadingSpinner';
import VenueRecommendationsCell from './VenueRecommendationsCell';
import VenueRecommendationsHeroCell from './VenueRecommendationsHeroCell';
import { tryParseInt } from '../../utils/string';

import './scss/venue-recommendations.module.scss';

import { StorableDataKeys } from '../../utils/storage';
import LocalStorage from '../../utils/storage';

export interface RouteMatchProps {
    venueId?: string;
}

export interface Props extends RouteComponentProps<RouteMatchProps> {
    venueId?: number;
    showBanner: boolean;
    recommendations: Venues.VenueRecommendation[];
    // action props
    dismissBanner: () => void;
    loadVenueRecommendations: (excludeIds?: number[]) => void;
}

export interface State {
    recommendations: Venues.VenueRecommendation[];
}

export class VenueRecommendations extends React.Component<Props, State> {
    componentDidMount() {
        // TODO: Migrate this to session store once we have API for it
        let visitedVenues = LocalStorage.getItem(StorableDataKeys.VISITED_VENUES, []);

        this.props.loadVenueRecommendations(visitedVenues);
    }

    render() {
        return (
            <div className='VenueRecommendations container-fix'>
                {/* xs screens get 6 regular cells */}
                <div className='nx-show-for-small-only'>
                    {this.props.showBanner && (
                        <div className='nx-banner nx-banner--success'>
                            <a className='nx-banner__close' onClick={() => this.props.dismissBanner()} />
                            <span className='nx-banner__title'>Your request has been sent!</span>
                            <span className='nx-banner__text'>
                                * Note that your request is pending until the venue replies back to you.
                            </span>
                        </div>
                    )}
                    <h1 className='VenueRecommendations--header'>Similar Venues</h1>

                    {this.props.recommendations.length === 0 && <Loading />}

                    <div className='VenueRecommendations--cellcontainer-xs'>
                        {this.props.recommendations.slice(0, 6).map((rec, idx) => {
                            return (
                                <div key={idx} className='col-sm-1'>
                                    <VenueRecommendationsCell position={idx} {...rec} />
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Non-xs screens get 2 hero cells and 4 regular cells */}
                <div className='nx-hide-for-small-only'>
                    {this.props.recommendations.length === 0 && <Loading />}

                    <div>
                        {this.props.recommendations.slice(0, 2).map((rec, idx) => {
                            return (
                                <div key={idx} className='col-sm-6 VenueRecommendations--herocellcontainer'>
                                    <VenueRecommendationsHeroCell position={idx} {...rec} />
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        {this.props.recommendations.slice(2, 6).map((rec, idx) => {
                            return (
                                <div className='col-sm-3 VenueRecommendations--cellcontainer' key={idx + 8}>
                                    <VenueRecommendationsCell position={idx + 2} {...rec} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    // Map the app state -> component props
    ({ venueRecommendations: { recommendations, showBanner } }: types.StoreState) => {
        return {
            recommendations: recommendations,
            showBanner: showBanner,
        };
    },
    // Map the dispatch -> component props
    (dispatch: ThunkDispatch<types.StoreState, undefined, actions.VenueRecommendationsActions>, ownProps: Props) => {
        return {
            dismissBanner: () => dispatch(actions.dismissBannerAction()),
            loadVenueRecommendations: (excludeIds?: number[]) => {
                const vid = ownProps.venueId || tryParseInt<undefined>(ownProps.match.params.venueId, undefined);
                if (!!vid) {
                    dispatch(actions.loadVenueRecommendationsAction(vid, excludeIds));
                } else {
                    console.error('VenueRecommendations: No seed venue ID specified, not loading recs');
                }
            },
        };
    }
)(VenueRecommendations);
