import * as React from 'react';
import { findDOMNode } from 'react-dom';

import cn from 'classnames';
import * as ReactDOM from 'react-dom';
import { Global } from '../helpers/Global';

import styles from './PageOverlay.module.scss';

interface Props {
    rootNodeId: string;
    isVisible: boolean;

    onClick: () => void;
    testId?: string;
}

export default class PageOverlay extends React.Component<Props> {
    private childrenWrapperRef: HTMLDivElement;

    constructor(props: Props) {
        super(props);
        this.handleOverlayClick = this.handleOverlayClick.bind(this);
    }

    render() {
        const rootElement = typeof window !== 'undefined' && document.getElementById(this.props.rootNodeId);
        if (!rootElement) {
            return null;
        }

        return ReactDOM.createPortal(
            <div
                className={cn(styles.className, { [styles.visible]: this.props.isVisible })}
                onClick={this.handleOverlayClick}
                data-testid={this.props.testId}
            >
                <div className={styles.inner} ref={(el: HTMLDivElement) => (this.childrenWrapperRef = el)}>
                    {this.props.children}
                </div>
                {this.props.isVisible && <Global preventScrolling='always' />}
            </div>,
            rootElement
        );
    }

    private handleOverlayClick(e: React.SyntheticEvent<HTMLDivElement>) {
        const childrenWrapper = findDOMNode(this.childrenWrapperRef) as HTMLDivElement;
        if (childrenWrapper !== e.target && !childrenWrapper.contains(e.target as Node)) {
            this.props.onClick();
        }
    }
}
