import * as React from 'react';
import classnames from 'classnames';
import { useState } from 'react';

import 'react-slidedown/lib/slidedown.css';

import { SlideDown } from 'react-slidedown';

interface Props {
    onSectionExpanded?: () => void;

    title: string;
    children: JSX.Element;
    isExpanded?: boolean;
    forceHide?: boolean; // set true to hide section content and not remove.
    // default (false) behaviour removes and adds content which leads to render each time.
}

const Section = (props: Props) => {
    const [isShown, setIsShown] = useState(props.isExpanded);

    const handleToggleClick = () => {
        const trigger = !isShown;
        setIsShown(trigger);
        if (trigger) {
            props.onSectionExpanded && props.onSectionExpanded();
        }
    };

    return (
        <div className='VenuePage--detail VenuePage--detail__expandable'>
            <div className='VenuePage--detail-headline-container' onClick={handleToggleClick}>
                <h3 className='VenuePage--detail-headline VenuePage--detail-headline__expandable'>
                    {props.title}
                    <i className={classnames('icon-hb-nx-arrow-down-16', { 'icon-hb-nx-arrow-down-16__flipped': isShown })} />
                </h3>
            </div>

            <div className='VenuePage--additional-detail-container'>
                <SlideDown className={'VenuePage--row'} closed={!isShown}>
                    {(isShown || props.forceHide) && <div className='VenuePage--additional-detail'>{props.children}</div>}
                </SlideDown>
            </div>
        </div>
    );
};

export default Section;
