export const VENUE_RECOMMENDATIONS_LOADED = 'VENUE_REOCMMENDATIONS_LOADED';
export type VENUE_RECOMMENDATIONS_LOADED = typeof VENUE_RECOMMENDATIONS_LOADED;

export const HOTEL_RECOMMENDATIONS_LOADED = 'HOTEL_RECOMMENDATIONS_LOADED';
export type HOTEL_RECOMMENDATIONS_LOADED = typeof HOTEL_RECOMMENDATIONS_LOADED;

export const VENUE_RECOMMENDATIONS_DISMISS_BANNER = 'VENUE_RECOMMENDATIONS_DISMISS_BANNER';
export type VENUE_RECOMMENDATIONS_DISMISS_BANNER = typeof VENUE_RECOMMENDATIONS_DISMISS_BANNER;

export const VENUE_RECOMMENDATIONS_REQUESTED = 'VENUE_RECOMMENDATIONS_REQUESTED';
export type VENUE_RECOMMENDATIONS_REQUESTED = typeof VENUE_RECOMMENDATIONS_REQUESTED;
