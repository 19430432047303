import {
    CSNDiamondLevel,
    CSNImageCategory,
    CSNVenueProfile,
    CSNVenueProfileAmenities,
    CSNVenueProfileImage,
} from '@weddingspot/ws-api-client';

export interface HotelRating {
    value?: number;
    type?: 'DIAMONDS' | 'STARS';
}

export interface QuickViewModeData {
    images?: {
        small: string;
        medium: string;
        large: string;
    }[];
    description?: string;
    guestRoomImage?: {
        small: string;
        medium: string;
        large: string;
    };
    rooms?: {
        single?: number;
        double?: number;
        suite?: number;
    };
    chain?: string;
    brand?: string;
    build?: number;
    renovated?: number;
    distanceFromAirport?: number;
    amenities?: string[];
}

const RATING_VALUES_MAP = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
};

const RATING_TYPE_MAP = {
    diamonds: {
        mainName: 'DIAMONDS',
        variants: ['DIAMOND', 'DIAMONDS'],
    },
    stars: {
        mainName: 'STARS',
        variants: ['STAR', 'STARS'],
    },
};

const ALL_RATING_TYPES = [...RATING_TYPE_MAP.diamonds.variants, ...RATING_TYPE_MAP.stars.variants];

export const getHotelRatingFromCsnFormat = (s?: string): HotelRating => {
    if (!s) {
        return {
            value: undefined,
            type: undefined,
        };
    }

    if (s === 'CATALUNYA_LUXE') {
        return {
            value: 5,
            type: 'STARS',
        };
    }

    const s_items = s.split('_');

    const ratingValueIntRaw = s_items.find((el: string) => Object.keys(RATING_VALUES_MAP).includes(el));
    const ratingValueInt = ratingValueIntRaw ? RATING_VALUES_MAP[ratingValueIntRaw] : 0;
    const ratingValueFrac = s_items.includes('HALF') ? 0.5 : 0;

    const ratingTypeRaw = s_items.find((el: string) => ALL_RATING_TYPES.includes(el));
    const ratingType = ratingTypeRaw
        ? RATING_TYPE_MAP.stars.variants.includes(ratingTypeRaw)
            ? 'STARS'
            : RATING_TYPE_MAP.diamonds.variants.includes(ratingTypeRaw)
            ? 'DIAMONDS'
            : undefined
        : undefined;

    return {
        value: ratingValueInt + ratingValueFrac || undefined,
        type: ratingType,
    };
};

/**
 * Comparison function for CSN diamond level (sorts high to low)
 */
export const compareDiamondRatings = (ratingA: CSNDiamondLevel, ratingB: CSNDiamondLevel) => {
    const order = [
        CSNDiamondLevel.BASIC,
        CSNDiamondLevel.HALF_DIAMOND,
        CSNDiamondLevel.ONE_DIAMOND,
        CSNDiamondLevel.TWO_DIAMOND,
        CSNDiamondLevel.THREE_DIAMOND,
        CSNDiamondLevel.FOUR_DIAMOND,
    ];

    const valA = order.indexOf(ratingA);
    const valB = order.indexOf(ratingB);

    if (valA < valB) {
        return 1;
    } else if (valA > valB) {
        return -1;
    } else {
        return 0;
    }
};

export const parseQuickViewData = (data: CSNVenueProfile): QuickViewModeData => {
    const rooms = data.images.filter((x) => x.group === CSNImageCategory.ROOM)?.map((x) => x.imageURL);
    const questRooms = data.images.filter((x) => x.group === CSNImageCategory.GUEST_ROOM)?.map((x) => x.imageURL);
    const sleepingRooms = data.images.filter((x) => x.group === CSNImageCategory.SLEEPING_ROOM)?.map((x) => x.imageURL);

    const allImages = [...sleepingRooms, ...questRooms, ...rooms];

    const parseAmenities = (amenities: CSNVenueProfileAmenities[]) => {
        const amenitiesToShow = [
            'Concierge Services',
            // There are two variants of Internet string
            'Internet Access',
            'Internet',
            'Room Service',
            'Laundry Service',
            'Street Parking',
            'Complimentary Parking',
            'Business Center',
            'Airport Shuttle',
            'Health Club',
        ];
        let availableInHotel = new Set();
        amenities.map((x) => x.amenityNameList.forEach((y) => availableInHotel.add(y)));
        return [...amenitiesToShow].filter((x) => availableInHotel.has(x));
    };

    return {
        images: allImages,
        description: data.basicProfile?.venueDescription,
        rooms: {
            single: data.guestRoom?.roomDetails.find((x) => x.type === 'SINGLE')?.count,
            double: data.guestRoom?.roomDetails.find((x) => x.type === 'DOUBLE')?.count,
            suite: data?.guestRoom?.roomDetails.find((x) => x.type === 'SUITE')?.count,
        },
        chain: data.highlights?.chainName,
        brand: data.highlights?.brandName,
        build: data.highlights?.buildYear,
        renovated: data.highlights?.renovationYear,
        distanceFromAirport: data.closestAirports?.find((x) => x.isVenueDefault)?.airportDistance.imperialValue,
        amenities: data.amenities?.length ? parseAmenities(data.amenities) : [],
    };
};

const hotelUtils = {
    getHotelRatingFromCsnFormat,
    compareDiamondRatings,
    parseQuickViewData,
};

export default hotelUtils;
