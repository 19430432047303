import * as React from 'react';
import Toast from './Toast';
import { useState } from 'react';

export interface ToastAction {
    text: string;
    onClick: () => void;
}

export interface ToastDataInterface {
    title?: string;
    text?: string;
    action?: ToastAction;
    type?: 'default' | 'success' | 'danger';
    Icon?: React.ReactNode;
    imageUrl?: string;
}

interface ToastContext {
    fire: (props: ToastDataInterface) => void;
}

export const ToastContext = React.createContext<ToastContext>({
    fire: () => {},
});

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
    const [toastData, setToastData] = useState<ToastDataInterface | null>(null);
    const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

    const fire = (fireData: ToastDataInterface) => {
        setToastData(fireData);
        const newTimer = setTimeout(() => {
            setToastData(null);
            setTimer(null);
        }, 5000);
        setTimer((prevState: ReturnType<typeof setTimeout> | null) => {
            if (prevState) {
                clearTimeout(prevState);
            }
            return newTimer;
        });
    };

    const onClose = () => {
        setToastData(null);
        setTimer((prevState: ReturnType<typeof setTimeout> | null) => {
            if (prevState) {
                clearTimeout(prevState);
            }
            return null;
        });
    };

    return (
        <ToastContext.Provider value={{ fire }}>
            {toastData && <Toast data={toastData} onClose={onClose} />}
            {children}
        </ToastContext.Provider>
    );
};
