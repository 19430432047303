import * as React from 'react';
import { findDOMNode } from 'react-dom';
import CventImage from '../cvent-image/index';
const { default: LazyLoad, forceVisible } = require('react-lazyload');

export interface Props {
    alt?: string;
    src: string;
    onLoad?: () => void;
    hidden?: boolean;
    forceLoad?: boolean;
    itemProp?: boolean;
    class?: string;
}

export default class SlideImage extends React.Component<Props> {
    static defaultProps: Partial<Props> = {
        hidden: false,
    };

    constructor(props: Props) {
        super(props);

        this.onLoad = this.onLoad.bind(this);
    }

    componentDidMount() {
        // There's an issue with LazyLoadImage component/React where when rendered isomorphically,
        // the image can be downloaded by the browser before the component can mount, thus causing
        // the `afterLoad` callback (which uses HTMLImageElement onLoad internally), never to get called
        // to work around this, we manually check the DOM node to see if the image has already been loaded
        // prior to the component mounting
        const img = findDOMNode(this) as HTMLImageElement;

        // img.complete = true if the image either loads or errors, this is why we check naturalWidth
        // which should be > 0 if an image actually loaded
        if (img && img.complete && img.naturalWidth !== 0) {
            this.onLoad();
        }

        if (this.props.forceLoad) {
            forceVisible();
        }
    }

    public render() {
        const renderElement = this.props.forceLoad === undefined || this.props.forceLoad === true;

        // if forceLoad not defined, then load normally (render element)
        // if forceLoad is false, don't render the element at all
        // if forceLoad is true, then load element with default visible

        return (
            renderElement && (
                <LazyLoad once={true} offset={450}>
                    <CventImage
                        className={this.props.class || 'SlideImage'}
                        alt={this.props.alt}
                        src={this.props.src}
                        hidden={this.props.hidden}
                        onLoad={this.onLoad}
                        itemProp={this.props.itemProp ? 'image' : undefined}
                    />
                </LazyLoad>
            )
        );
    }

    private onLoad() {
        this.props.onLoad && this.props.onLoad();
    }
}
