import * as React from 'react';
import { UseTypeaheadOptions } from './store';

export interface TypeaheadContext {
    dataUrl: string;
    dataTTL: number;
    options: UseTypeaheadOptions;
    onSearchResultClicked?: (link: string, inputValue?: string) => void;
}

export const emtpyContext = {
    dataUrl: '',
    dataTTL: 1,
    options: {},
};

const TypeaheadContextType: React.Context<TypeaheadContext> = React.createContext<TypeaheadContext>(emtpyContext);
const TypeaheadContextProvider = TypeaheadContextType.Provider;
const TypeaheadContextConsumer = TypeaheadContextType.Consumer;

export { TypeaheadContextType, TypeaheadContextProvider, TypeaheadContextConsumer };
