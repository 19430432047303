import * as React from 'react';
import { ShoppingCartContext } from './ShoppingCartContext';
import Button from '@cvent/carina/components/Button';
import { Placements } from '@cvent/carina/components/Popover';
import { Flyout } from '@cvent/carina/components/Flyout';

interface ShoppingCartButtonProps {
    itemDescriptor: (count: number) => string;
    carinaIcon?: any;
    isMobile?: boolean;
    isFixed?: boolean;
    experienceContent?: (closeFlyout: () => void) => JSX.Element;
}

import styles from './ShoppingCartButton.module.scss';

export const ShoppingCartButton = (props: ShoppingCartButtonProps) => {
    const { itemDescriptor, carinaIcon: CarinaIcon, isFixed, experienceContent } = props;

    const { cartOpen, setCartOpen, items, customButtonStyle } = React.useContext(ShoppingCartContext);

    if (props.isMobile) {
        return experienceContent && !isFixed ? (
            <div className={styles.shoppingCartFlyout}>
                <Flyout
                    placement={Placements.bottomEnd}
                    defaultOpen={true}
                    isFixed={true}
                    content={experienceContent}
                    // @ts-ignore
                    className={'Oelk'}
                    // @ts-ignore
                    trigger={() => (
                        <div className={`${styles.container} ${isFixed ? styles.isFixed : ''}`} onClick={() => setCartOpen(!cartOpen)}>
                            <div className={styles.body}>
                                {CarinaIcon && <CarinaIcon />}
                                <div className={styles.label}>{items.length}</div>
                            </div>
                        </div>
                    )}
                />
            </div>
        ) : (
            <div
                className={`${styles.container} ${isFixed ? styles.isFixed : ''}`}
                onClick={() => setCartOpen(!cartOpen)}
                style={isFixed ? customButtonStyle || {} : {}}
            >
                <div className={styles.body}>
                    {CarinaIcon && <CarinaIcon />}
                    <div className={styles.label}>{items.length}</div>
                </div>
            </div>
        );
    }

    return experienceContent && !cartOpen ? (
        <div className={styles.shoppingCartFlyout}>
            <Flyout
                placement={Placements.bottomEnd}
                defaultOpen={true}
                isFixed={true}
                content={experienceContent}
                // @ts-ignore
                trigger={({ toggleClose }) => (
                    <Button
                        text={itemDescriptor(items.length)}
                        onClick={() => {
                            setCartOpen(!cartOpen);
                            toggleClose();
                        }}
                        icon={CarinaIcon}
                        appearance={items.length > 0 ? 'filled' : 'lined'}
                        accessibilityLabel={'Shopping Cart'}
                    />
                )}
            />
        </div>
    ) : (
        <Button
            text={itemDescriptor(items.length)}
            onClick={() => {
                setCartOpen(!cartOpen);
            }}
            icon={CarinaIcon}
            appearance={items.length > 0 ? 'filled' : 'lined'}
            accessibilityLabel={'Shopping Cart'}
        />
    );
};
