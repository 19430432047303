import * as React from 'react';
import Rating, { RatingProps } from '../csn/Rating';
import { formatCventImage } from '../../utils/imageUtils';
import XIcon from '@cvent/carina/components/Icon/X';

import styles from './CartItem.module.scss';

interface CartItemProps {
    id: string | number;
    image?: string;
    title: string;
    subtitle?: string;
    rating?: RatingProps; // CSN rating enum/string (e.g. ONE_STAR)
    onClickRemove?: (id: string | number) => void;
    additionalSubtitle?: string | React.ReactNode;
}

export const CartItem = (props: CartItemProps) => {
    const { id, image, title, subtitle, rating, onClickRemove, additionalSubtitle } = props;

    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>{image && <img src={formatCventImage({ src: image })} />}</div>
            <div className={styles.descriptorContainer}>
                <div className={styles.title}>{title}</div>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {rating ? <Rating {...rating} color='#69717A' /> : null}
                {additionalSubtitle && <div className={styles.subtitle}>{additionalSubtitle}</div>}
            </div>
            {onClickRemove && (
                <div className={styles.closeContainer} onClick={() => onClickRemove(id)}>
                    <XIcon color='#69717A' />
                </div>
            )}
        </div>
    );
};
