import * as React from 'react';
import axios from 'axios';
import Fuse from 'fuse.js';

import LocalStorage from '../../utils/storage';
import { useMemo } from 'react';
import { TypeaheadContextType } from './TypeaheadContext';

/**
 * Types for data stored in typeahead JSON file
 */
enum ItemType {
    location = 0,
    style,
    venue,
}

interface SearchItem {
    id: number;
    name: string;
    mkt: number; // mkt mean marketId
    url: string;
    url_name: string;
    type: ItemType;
}

interface RawTypeaheadData {
    locations: SearchItem[];
    styles: SearchItem[];
    venues: SearchItem[];
}

/**
 * Types for the Typeahead store
 */
export interface TypeaheadSearchResult {
    name: JSX.Element;
    link: string;
}

type TypeaheadFuse = Fuse<SearchItem, Fuse.FuseOptions<SearchItem>>;

interface FuseSearchResults {
    item: SearchItem;
    matches: {
        arrayIndex: number;
        indices: [number, number][];
        key: string;
        value: string;
    }[];
}

/**
 * Exported store types
 */
export interface TypeaheadState {
    q: string;

    matchedStyles: TypeaheadSearchResult[];
    matchedVenues: TypeaheadSearchResult[];
    matchedLocations: TypeaheadSearchResult[];

    isLoad?: boolean;
}

export interface TypeaheadActions {
    setQ: (q: string) => void;
}

export interface TypeaheadStore {
    storeState: TypeaheadState;
    storeActions: TypeaheadActions;
}

export interface UseTypeaheadOptions {
    maxResultsPerSection?: number;
    locationUrlFormatter?: (slug: string) => string;
    venueUrlFormatter?: (venueId: number | string, slug?: string) => string;
    styleUrlFormatter?: (slug: string) => string;
}

/**
 * Utility for storing typeahead data to browser localstorage
 *
 * @param key key in localstorage
 * @param data the data to be stored
 */
const saveRawTypeaheadDataToLocalStorage = (key: string, data: RawTypeaheadData) => {
    LocalStorage.setItem(key + 'locations', data.locations);
    LocalStorage.setItem(key + 'styles', data.styles);
    LocalStorage.setItem(key + 'venues', data.venues);
    LocalStorage.setItem(key + 'timestamp', + new Date());
};

/**
 * Utility to fetch and format data from browser localstorage
 * @param key key in localstorage
 * @param dataTTL TTL of stored data
 */
const loadRawTypeaheadDataFromLocalStorage = (key: string, dataTTL: number): null | RawTypeaheadData => {
    const timestamp = LocalStorage.getItem(key + 'timestamp');
    // invalidate local storage data
    if (!timestamp || + new Date() - timestamp > dataTTL) {
       return null;
    }
    const locations = LocalStorage.getItem(key + 'locations');
    const styles = LocalStorage.getItem(key + 'styles');
    const venues = LocalStorage.getItem(key + 'venues');
    if (locations !== null && styles !== null && venues !== null) {
        return {
            locations: locations,
            styles: styles,
            venues: venues,
        };
    }
    return null;
};

/**
 * Typeahead uses fuse.js to power the partial string matching search,
 * this function creates a Fuse instance given some raw typeahead data from
 * the typeahead JSON file
 *
 * @param data raw typeahead data
 */
const createFuse = (data: RawTypeaheadData) => {
    const locations = data.locations.map((l: SearchItem) => ({
        id: 0,
        name: l.name,
        url: l.url,
        mkt: l.mkt,
        url_name: '',
        type: ItemType.location,
    }));

    const styles = data.styles.map((s: SearchItem) => ({
        id: s.id,
        url_name: s.url_name,
        name: s.name,
        url: '',
        type: ItemType.style,
        mkt: 0,
    }));

    const venues = data.venues.map((v: SearchItem) => ({
        id: v.id,
        name: v.name,
        mkt: v.mkt,
        url_name: v.url_name,
        type: ItemType.venue,
        url: '',
    }));

    return new Fuse<SearchItem, Fuse.FuseOptions<SearchItem>>([...locations, ...styles, ...venues], {
        shouldSort: true,
        threshold: 0.1,
        location: 0,
        tokenize: false,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 2,
        includeMatches: true,
        keys: ['name'],
    });
};

/**
 * Fetches raw typeahead data from the wedding spot CDN and saves it to localstorage
 *
 * @param dataUrl URL to fetch data with
 * @param storageKey the localstorage key
 * @param updateFuse callback for setting the initialized fuse instance
 */
const fetchRawTypeaheadData = (dataUrl: string, storageKey: string, updateFuse: (fuse: TypeaheadFuse) => void) => {
    axios.get<RawTypeaheadData>(dataUrl).then(({ data }) => {
        updateFuse(createFuse(data));
        saveRawTypeaheadDataToLocalStorage(storageKey, data);
    });
};

/**
 * Given a string, searches for substrings via the provided fuse instance
 *
 * @param fuse fuse instance
 * @param q the string to search for
 */
const search = (fuse: TypeaheadFuse, q: string, maxResultsPerSection: number, urlFormatters: UseTypeaheadOptions) => {
    const { locationUrlFormatter, styleUrlFormatter, venueUrlFormatter } = urlFormatters;

    if (q.length < 2) {
        return {
            matchedStyles: [],
            matchedLocations: [],
            matchedVenues: [],
        };
    }

    const results = fuse.search(q) as FuseSearchResults[];

    const locations: TypeaheadSearchResult[] = [];
    const venues: TypeaheadSearchResult[] = [];
    const styles: TypeaheadSearchResult[] = [];

    results.forEach(({ item, matches: [match] }) => {
        if (!match) {
            return;
        }

        const [[start, end]] = match.indices; // TODO why double array destruction?
        const name = item.name;
        const res = (
            <>
                {name.slice(0, start)}
                <b>{name.slice(start, end + 1)}</b>
                {name.slice(end + 1)}
            </>
        );
        if (item.type === ItemType.location) {
            locations.push({
                name: res,
                link: locationUrlFormatter ? locationUrlFormatter(item.url.toLowerCase()) : '',
            });
        }
        if (item.type === ItemType.venue) {
            venues.push({
                name: res,
                link: venueUrlFormatter ? venueUrlFormatter(item.id, item.url_name.toLowerCase()) : '',
            });
        }
        if (item.type === ItemType.style) {
            styles.push({
                name: res,
                link: styleUrlFormatter ? styleUrlFormatter(item.url_name.toLowerCase()) : '',
            });
        }

    });

    return {
        matchedStyles: styles.slice(0, maxResultsPerSection),
        matchedLocations: locations.slice(0, maxResultsPerSection),
        matchedVenues: venues.slice(0, maxResultsPerSection),
    };
};

const LOCAL_STORAGE_KEY = 'ws-typeahead-data';
const VERSION = '1';
const FULL_STORAGE_KEY = LOCAL_STORAGE_KEY + 'v-' + VERSION;

/**
 * Typeahead hook, handles all the intialization and exposes methods for
 * searching
 */
export const useTypeahead = (maxResultsPerSection: number = 5): TypeaheadStore => {
    const [queryState, setQueryState] = React.useState('');
    const [fuseInstance, setFuseInstance] = React.useState<TypeaheadFuse | null>(null);
    const { dataUrl, dataTTL, options } = React.useContext(TypeaheadContextType);

    // Effect for initializing fuse
    React.useEffect(() => {
        if (!LocalStorage.isLocalStorageAvailable()) {
            return;
        }

        const existingData = loadRawTypeaheadDataFromLocalStorage(FULL_STORAGE_KEY, dataTTL);
        if (existingData) {
            setFuseInstance(createFuse(existingData));
        } else {
            fetchRawTypeaheadData(dataUrl, FULL_STORAGE_KEY, (instance: TypeaheadFuse) => setFuseInstance(instance));
        }
    }, []);

    // Actions available for consumers
    const actions: TypeaheadActions = {
        setQ: (q: string) => {
            setQueryState(q);
        },
    };

    const results = useMemo(() => {
        if (!fuseInstance) {
            return {
                matchedStyles: [],
                matchedLocations: [],
                matchedVenues: [],
            };
        }
        return search(fuseInstance, queryState, maxResultsPerSection, options);
    }, [fuseInstance, queryState, maxResultsPerSection, options]);

    return {
        storeState: {
            q: queryState,
            ...results,
            isLoad: Boolean(fuseInstance),
        },
        storeActions: actions,
    };
};
