import * as React from 'react';
import cls from 'classnames';

import styles from './RingsSpinner.module.scss';
import loadingPlaceholder from '../../assets/images/rings.svg';

interface Props {
    animate: boolean;
}

export const RingsSpinner: React.FC<Props> = ({ animate }) => {
    return (
        <img
            src={loadingPlaceholder?.src || loadingPlaceholder}
            className={cls(styles.ringsSpinner, { [styles.ringsSpinner__animated]: animate })}
        />
    );
};
