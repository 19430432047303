import * as React from 'react';
import cls from 'classnames';
import { Formik, Form, Field, FormikProps, FormikHelpers as FormikActions } from 'formik';

import PageOverlay from '../page-overlay';

import { WSAppContext, WSAppContextType, AuthFormData } from '../../providers/ws-app';
import { Auth } from '@weddingspot/ws-api-client';

import styles from './AuthModal.module.scss';

const genericLoginError = 'An error occurred while attempting to log in, please try again later';

export interface AuthModalFormData {
    email: string;
    password: string;
}

export interface Props {
}

interface State {
    loadFb: boolean;
    facebookError: string | undefined;
}

export default class AuthModal extends React.Component<Props, State> {
    static contextType: React.Context<WSAppContextType> = WSAppContext;
    // @ts-ignore
    context!: React.ContextType<typeof WSAppContext>;

    constructor(props: Props) {
        super(props);

        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.renderFormField = this.renderFormField.bind(this);
        this.validateForm = this.validateForm.bind(this);

        this.state = {
            facebookError: undefined,
            loadFb: false,
        };
    }

    componentDidMount() {
        if (!this.state.loadFb && this.context.authModalOpen) {
            this.setState({ loadFb: true });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!prevState.loadFb && this.context.authModalOpen) {
            this.setState({ loadFb: true });
        }
    }

    public render() {
        const { closeAuthModal, authModalOpen, rootNodeId, isForceAuth } = this.context;

        return (
            <PageOverlay
                rootNodeId={rootNodeId}
                onClick={isForceAuth ? () => {} : closeAuthModal}
                isVisible={authModalOpen}
                testId='AuthModalPageOverlay'
            >
                <div className={styles.className}>
                    <div className={styles.header}>
                        <div className={styles.headline}>Sign In</div>
                        {!isForceAuth && (
                            <span
                                className={cls(styles.closeButton, 'icon', 'icon-hb-nx-close')}
                                onClick={closeAuthModal}
                                data-testid='AuthModal--closeButton'
                            />
                        )}
                    </div>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validate={this.validateForm}
                        onSubmit={this.handleFormSubmit}
                    >
                        {({ isSubmitting, errors, status, touched }: FormikProps<AuthModalFormData>) => (
                            <Form>
                                <div>
                                    <div className={styles.content}>
                                        <div>
                                            {this.renderFormField(
                                                'email',
                                                'email',
                                                'Email',
                                                !!touched.email && !!errors.email,
                                                errors.email,
                                                'Email'
                                            )}

                                            {this.renderFormField(
                                                'password',
                                                'password',
                                                'Password',
                                                !!touched.password && !!errors.password,
                                                errors.password,
                                                'Password'
                                            )}

                                            {status && <div className={styles.formLevelError}>{status}</div>}
                                        </div>
                                    </div>
                                </div>
                                <div className={cls(styles.footer, styles.content)}>
                                    <button
                                        type='submit'
                                        className={cls(styles.signInButton, 'nx-button', 'nx-button--primary')}
                                        disabled={isSubmitting}
                                    >
                                        Sign In
                                    </button>

                                    <div className={styles.footerLinks}>
                                        <div className={styles.footerLink}>
                                            <a href='#' onClick={this.handleCreateClick}>
                                                Create
                                            </a>
                                            {'\u00a0'}a Free Account
                                        </div>
                                        <div className={styles.footerLink}>
                                            <a href='/resetpassword/' className={styles.passwordResetLink}>
                                                Forgot password
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </PageOverlay>
        );
    }

    private renderFormField(fieldName: string, type: string, placeholder: string, hasError: boolean, errorMsg: string | undefined, label: string) {
        return (
            <div className={cls(styles.inputWrapper, { [styles.hasError]: hasError })}>
                <Field
                    className={cls(styles.input, { [styles.hasError]: hasError })}
                    name={fieldName}
                    type={type}
                    placeholder={placeholder}
                    aria-label={label}
                />
                {hasError && <div className={styles.fieldError}>{errorMsg}</div>}
            </div>
        );
    }

    private validateForm(values: AuthModalFormData) {
        let errors: Partial<AuthModalFormData> = {};
        if (!values.email) {
            errors.email = 'Please enter an email';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Please enter a valid email address';
        }
        if (!values.password) {
            errors.password = 'Please enter a password';
        }
        return errors;
    }

    private handleFormSubmit(values: AuthFormData, actions: FormikActions<AuthFormData>) {
        this.context
            .signIn(values)
            .then(() => {
                this.context
                    .loadUserData()
                    .then(() => {
                        this.context.closeAuthModal();
                        this.context.setIsForceAuth(false);
                    })
                    .catch(() => {
                        actions.setStatus(genericLoginError);
                    })
                    .then(() => {
                        actions.setSubmitting(false);
                    });
            })
            .catch(() => {
                actions.setStatus('Invalid email or password, please try again');
                actions.setSubmitting(false);
            });
    }

    private handleCreateClick(e: React.SyntheticEvent<HTMLAnchorElement>) {
        e.preventDefault();
        this.context.openSignupModal();
    }
}
