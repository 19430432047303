import * as React from 'react';
import { HeaderContext } from '../header';
import { WSAppContext } from '../../providers/ws-app';
import { useContext, useEffect, useState } from 'react';

import ChevronLeftIcon from '@cvent/carina/components/Icon/ChevronLeft';

import styles from './Subheader.module.scss';

export enum SubHeaderTypes {
    venueProfile = 'venueProfile',
    spotEstimate = 'spotEstimate',
}

export interface Props {
    headerType: SubHeaderTypes;
    spotEstimatesURL: string;
    className?: string;
    onBreacrumbsClick?: () => void;
    onBackLinkClick?: () => void;
    onVenueProfileClick?: () => void;
    onMySavedEstimatesClick?: () => void;
}

export interface BreadcrumbLink {
    label: string;
    url: string;
}

export const SubHeader = (props: Props) => {
    const { spotEstimatesURL, onBreacrumbsClick, onBackLinkClick, headerType, onMySavedEstimatesClick, onVenueProfileClick } = props;

    const { headerData } = useContext(HeaderContext);
    const { userIsAuthenticated } = useContext(WSAppContext);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<BreadcrumbLink[]>([]);

    React.useEffect(() => {
        headerData?.breadcrumbs &&
            setBreadcrumbLinks(
                headerData.breadcrumbs.map((item) => ({
                    label: item.name,
                    url: item.url.toLowerCase(),
                }))
            );
    }, [headerData?.breadcrumbs]);

    const renderBreadcrumbs = () => {
        return (
            <div className={styles.breadcrumbs}>
                {breadcrumbLinks.map((item, idx) => {
                    const isLast = idx === breadcrumbLinks.length - 1;
                    return (
                        <a
                            key={idx}
                            onClick={onBreacrumbsClick}
                            href={isLast ? undefined : item.url}
                            className={styles.breadcrumbLink}
                            itemType='breadcrumb'
                        >
                            {item.label}
                        </a>
                    );
                })}
            </div>
        );
    };

    const renderBackLink = () => {
        const url = breadcrumbLinks.slice(-2)[0]?.url;

        return (
            url && (
                <div>
                    <a onClick={onBackLinkClick} href={breadcrumbLinks.slice(-2)[0]?.url} className={styles.breadcrumbLink}>
                        <ChevronLeftIcon />
                        <span>BACK TO SEARCH RESULTS</span>
                    </a>
                </div>
            )
        );
    };

    const renderVenueProfileLink = () => {
        const url = breadcrumbLinks.slice(-1)[0]?.url;

        return (
            url && (
                <div className={styles.buttonContainer} onClick={onVenueProfileClick}>
                    <a href={breadcrumbLinks.slice(-1)[0]?.url} className={`${styles.button} ${styles.estimate}`}>
                        VENUE PROFILE
                    </a>
                </div>
            )
        );
    };

    const renderSpotEstimatesLink = () => {
        return (
            userIsAuthenticated && (
                <div className={styles.buttonContainer} onClick={onMySavedEstimatesClick}>
                    <a href={spotEstimatesURL} className={`${styles.button} ${styles.estimate}`}>
                        MY SAVED ESTIMATES
                    </a>
                </div>
            )
        );
    };

    return (
        <div className={props.className || styles.subHeaderContainer}>
            {headerType === SubHeaderTypes.venueProfile && <span className={styles.breadcrumbArrow + ' icon-hb-arrowleft-b'} />}

            {headerType === SubHeaderTypes.venueProfile && renderBreadcrumbs()}
            {headerType === SubHeaderTypes.spotEstimate && renderBackLink()}

            <div className={styles.rightNav}>
                {headerType === SubHeaderTypes.spotEstimate && renderVenueProfileLink()}
                {renderSpotEstimatesLink()}
            </div>
        </div>
    );
};
