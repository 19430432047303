import * as actions from './actions';
import * as constants from './constants';
import * as types from './types';
import { Venues } from '@weddingspot/ws-api-client';
import { InquiryType } from '../appointments/constants';

const defaultState = {
    showBanner: true,
    recommendations: [],
    recommendationsLoaded: false,
    hotels: [],
    hotelsLoaded: false,
    lastRecTypeRequested: null,
};

export function venueRecommendations(state: types.VenueRecommendationsState = defaultState, action: actions.VenueRecommendationsActions) {
    switch (action.type) {
        case constants.VENUE_RECOMMENDATIONS_LOADED:
            const recs = action.payload;

            let recType;
            if (recs.length > 0) {
                recType = recs[0].rec_type;
            }

            // Make sure the recommendations that were loaded match the last
            // type that was requested
            if (
                (recType === Venues.RecommendationType.WEDDING && state.lastRecTypeRequested === InquiryType.WEDDING) ||
                (recType === Venues.RecommendationType.REHEARSAL_DINNERS && state.lastRecTypeRequested === InquiryType.REHEARSAL_DINNER) ||
                !state.lastRecTypeRequested
            ) {
                return {
                    ...state,
                    recommendations: action.payload,
                    recommendationsLoaded: true,
                };
            }

            // Otherwise don't do anything (UI will wait until the right recs are loaded)
            return { ...state };
        case constants.HOTEL_RECOMMENDATIONS_LOADED:
            return {
                ...state,
                hotels: action.payload,
                hotelsLoaded: true,
            };
        case constants.VENUE_RECOMMENDATIONS_DISMISS_BANNER:
            return {
                ...state,
                showBanner: false,
            };
        case constants.VENUE_RECOMMENDATIONS_REQUESTED:
            return {
                ...state,
                lastRecTypeRequested: action.payload,
            };
        default:
            return state;
    }
}
