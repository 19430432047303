export const SRP_VIEWS = {
    LIST: 'list',
    MAP: 'map',
    GRID: 'grid',
};

export const CONTRACT_PACKAGE = {
    PAID_PREMIUM: 'Paid Premium',
    PAID_FEATURED: 'Paid Featured',
    PAID_BASIC: 'Paid Basic',
    FREE: 'Free',
};

export const GUEST_USER_ID = '00000000-0000-0000-0000-000000000000';

export const BUSINESS_UNIT = 'hospitality';

export const COMPONENT = 'csn';

export const PRODUCT = 'weddingspot';

export const PERSONA = {
    Planner: 'planner',
    Vendor: 'vendor',
}