import * as React from 'react';
import { useContext } from 'react';
import { WSAppContext } from '../../providers/ws-app';

const Link = (
    props: React.PropsWithChildren<{
        url: string;
        className?: string;
        onClick?: () => void;
    }>
): JSX.Element => {
    const { linkComponent } = useContext(WSAppContext);

    return linkComponent(props);
};

export default Link;
