import * as React from 'react';

import Button from '@cvent/carina/components/Button';
import Carousel from '@cvent/carina/components/Carousel/Carousel';
import { useSlide } from '@cvent/carina/components/Carousel/SlideContext';

import styles from './ShoppingCardExperience.module.scss';

export type ImageSrcSet = {
    webp: string;
    jpeg: string;
    fallbackImage: string;
};

export interface ShoppingCardExperienceSlide {
    image?: string;
    imageSet?: ImageSrcSet;
    videoMp4?: string;
    videoWebM?: string;
    title: string;
    description: string;
}

interface ShoppingCardExperienceSlideComponent extends ShoppingCardExperienceSlide {
    active: boolean;
    setActiveSlideIndex: (i: number) => void;
}

type NonEmptyArray<T> = T[] & { 0: T };

interface ShoppingCardExperienceProps {
    slides: NonEmptyArray<ShoppingCardExperienceSlide>;
    onClose?: () => void; // method for close at all
    onCloseButton?: () => void; // method for close on 'close' button (using in log events)
    onRendered?: () => void;
    onNext?: () => void;
}

const ShoppingCardExperienceSlide = (props: ShoppingCardExperienceSlideComponent) => {
    const { setSlideActive, distanceFromVisible, index } = useSlide();

    const { image, videoMp4, videoWebM, title, description, active, setActiveSlideIndex, imageSet } = props;

    // activation based on external buttons
    React.useEffect(() => {
        if (active && distanceFromVisible !== 0) {
            setSlideActive();
        }
    }, [active]);

    // activation based on carina Dots element
    React.useEffect(() => {
        if (distanceFromVisible === 0) {
            setActiveSlideIndex(index);
        }
    }, [distanceFromVisible]);

    return (
        <div className={styles.body}>
            <div className={styles.bodyMedia}>
                {(videoMp4 || videoWebM) && (
                    <video autoPlay={true} muted={true} loop={true} playsInline={true}>
                        {videoMp4 && <source src={videoMp4} type='video/mp4' />}
                        {videoWebM && <source src={videoWebM} type='video/webm' />}
                        Your browser does not support the video tag.
                    </video>
                )}
                {image && <img src={image} alt='dummy' />}
                {imageSet && (
                    <picture>
                        <source srcSet={imageSet.webp} type='image/webp' />
                        <source srcSet={imageSet.jpeg} type='image/jpeg' />
                        <img src={imageSet.fallbackImage} alt='dummy' />
                    </picture>
                )}
            </div>
            <div className={styles.bodyContent}>
                <h1>{title}</h1>
                <span>{description}</span>
            </div>
        </div>
    );
};

export const ShoppingCardExperience = (props: ShoppingCardExperienceProps) => {
    const { slides, onClose, onCloseButton, onRendered, onNext } = props;

    // onRendered
    React.useEffect(() => {
        onRendered && onRendered();
    }, []);

    const _length = slides.length - 1;

    const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);

    const nextSlide = () => {
        setActiveSlideIndex(activeSlideIndex === _length ? 0 : activeSlideIndex + 1);
        onNext && onNext();
    };
    const previousSlide = () => {
        setActiveSlideIndex(activeSlideIndex === 0 ? _length : activeSlideIndex - 1);
    };

    const onButtonClose = () => {
        onCloseButton && onCloseButton();
        onClose && onClose();
    };

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <Carousel cols={1} showOverhang={false} hideNumbers={true} insetControls={true}>
                    {slides.map((s, i) => (
                        <ShoppingCardExperienceSlide
                            active={i === activeSlideIndex}
                            setActiveSlideIndex={(i: number) => setActiveSlideIndex(i)}
                            key={i}
                            {...s}
                        />
                    ))}
                </Carousel>
            </div>
            <div className={styles.footer}>
                <div className={styles.footerButton}>
                    <Button
                        appearance='lined'
                        text={activeSlideIndex === 0 ? 'Close' : 'Previous'}
                        onClick={activeSlideIndex === 0 ? onButtonClose : previousSlide}
                    />
                </div>
                <div className={styles.footerButton}>
                    <Button
                        appearance='filled'
                        text={activeSlideIndex === _length ? 'Done' : 'Next'}
                        onClick={activeSlideIndex === _length ? onClose : nextSlide}
                    />
                </div>
            </div>
        </div>
    );
};
