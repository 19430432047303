import * as React from 'react';
import Typeahead from './Typeahead';
import { useTypeahead } from './store';
import { useState } from 'react';
import { TypeaheadContextType } from './TypeaheadContext';

interface Props {
    className?: string;
    containerClassName?: string;
    isSearchIconShown?: boolean;
    isSearchIconCarinaShown?: boolean;
    onInputFocus?: (value: string) => void;
    onInputChange?: (value: string) => void;
    onInputClick?: (value: string) => void;
    setVenueId ?: (field: string, value: any, shouldValidate?: boolean)=>void;
    responsive ?: boolean;
    brandColorBackground ?: boolean;
    showVenuesOnly ?: boolean;
    disableVenueRedirection ?: boolean;
    showSearchIcon ?: boolean;
}

const TypeaheadContainer = (props: Props) => {
    const [isShow, setIsShow] = useState(false);
    const { storeActions, storeState } = useTypeahead();
    const { onSearchResultClicked } = React.useContext(TypeaheadContextType);

    React.useEffect(() => {
        const callback = (e: MouseEvent) => {
            const eventTarget = e.target as Element | null;
            if (!eventTarget || !eventTarget.closest || !eventTarget.closest('[data-typeahead=true]')) {
                setIsShow(false);
            }
        };
        document.addEventListener('click', callback);
        return () => document.removeEventListener('click', callback);
    }, []);

    const handleInputChange = (q: string) => {
        props.onInputChange && props.onInputChange(q);
        storeActions.setQ(q);
    };

    const onInputFocus = () => {
        props.onInputFocus && props.onInputFocus(storeState.q);
        setIsShow(true);
    };

    const onInputClick = () => {
        props.onInputClick && props.onInputClick(storeState.q);
    };

    const { q, matchedVenues, matchedLocations, matchedStyles } = storeState;
    const isAnythingFound = !!matchedLocations.length || !!matchedVenues.length || !!matchedStyles.length;
    return (
        <Typeahead
            containerClassName={props.containerClassName}
            className={props.className}
            onChange={handleInputChange}
            isSearchIconShown={props.isSearchIconShown}
            isSearchIconCarinaShown={props.isSearchIconCarinaShown}
            inputValue={q}
            locations={matchedLocations}
            styles={matchedStyles}
            venues={matchedVenues}
            isSearchMenuShown={isShow && isAnythingFound}
            onInputFocus={onInputFocus}
            onInputClick={onInputClick}
            onSearchResultClick={onSearchResultClicked}
            setVenueId={props.setVenueId}
            responsive ={props.responsive}
            brandColorBackground ={props.brandColorBackground}
            showVenuesOnly ={props.showVenuesOnly}
            disableVenueRedirection ={props.disableVenueRedirection}
            showSearchIcon ={props.showSearchIcon}

        />
    );
};

export { TypeaheadContainer as Typeahead };
export * from './store';
