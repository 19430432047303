import Button from '@cvent/carina/components/Button';
import React from 'react';
import Modal from '@cvent/carina/components/Modal';
import WalkingAwayIcon from './WalkingAwayIcon';
import { AuthProvider } from '@weddingspot/ws-api-client'
import { ModalFormat, SCREEN_WIDTH_BREAKPOINT, SESSION_EXTEND_WIDGET_DISPLAY_TIMEOUT, Z_INDEX } from './constant';
import styles from './SessionExtend.module.scss';
import { handleTimeoutToDisplaySessionExtendWidget } from './utils';
import { StorableDataKeys } from '../../utils/storage';

interface Props {
    isOpen:boolean;
    setIsOpen: (isOpen:boolean) => void;
}

export const SessionExtend = (props:Props) => {
    
    const [format, setFormat] = React.useState<ModalFormat>(ModalFormat.MEDIUM);

    const setFormatDataHandler = () => {
        if (window.innerWidth > SCREEN_WIDTH_BREAKPOINT) {
            setFormat(ModalFormat.MEDIUM);
        } else {
            setFormat(ModalFormat.SMALL);
        }
    };

    React.useEffect(() => {
        setFormatDataHandler();
        window.addEventListener('resize', setFormatDataHandler);
        return () => {
            window.removeEventListener('resize', setFormatDataHandler);
        };
    }, []);
    
    let screenDisplayTimeout: NodeJS.Timeout ;

    React.useEffect(()=>{
        
        if ( props.isOpen) {
            screenDisplayTimeout = setTimeout(()=>{
                const userLastActivity:number = parseInt(localStorage.getItem(StorableDataKeys.USER_LAST_ACTIVITY)!, 10);
                const currentTime:number = Date.now();
                if(currentTime-userLastActivity < SESSION_EXTEND_WIDGET_DISPLAY_TIMEOUT) {
                    props.setIsOpen(false);
                    handleTimeoutToDisplaySessionExtendWidget(props.setIsOpen)
                    return;
                }
                logoutHandler();
            },SESSION_EXTEND_WIDGET_DISPLAY_TIMEOUT);
        }
    },[props.isOpen])
    
    const extendSessionHandler = ()=>{
        clearTimeout(screenDisplayTimeout);
        props.setIsOpen(false);
        AuthProvider.extendSession();
    }

    const logoutHandler = ()=>{
        clearTimeout(screenDisplayTimeout);
        props.setIsOpen(false);
        AuthProvider.logout();      
    }
    

    return (
        <div>
            <Modal format={format} isOpen={props.isOpen} zIndex={Z_INDEX} >
                <div id={styles.container} >
                    <WalkingAwayIcon/>
                    <h3 id={styles.heading}> Need more time?</h3>
                    <h5 id={styles.subheading} >You’ve been away for a while, so we’re about to log you out. </h5>
                    <Button
                        id={'exploreVenuesBtn'}
                        text='Continue'
                        appearance={'filled'}
                        type='submit'
                        onClick={extendSessionHandler}
                    />
                    <br></br>
                    <Button
                        id={'LogOutBtn'}
                        text='Log Out'
                        appearance="ghost"
                        type='submit'
                        onClick={logoutHandler}
                    />
                </div>
            </Modal>
        </div>
    );
};

