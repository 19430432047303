import * as React from 'react';
import { Link } from 'react-router-dom';

const LinkRouter = (
    props: React.PropsWithChildren<{
        url: string;
        className?: string;
        onClick?: () => void;
    }>
) => {
    return (
        <Link to={props.url} className={props.className} onClick={props.onClick}>
            {props.children}
        </Link>
    );
};

export default LinkRouter;
