import * as React from 'react';
import cls from 'classnames';
import { Field } from 'redux-form';
import { Venues } from '@weddingspot/ws-api-client';

import { toUSD } from '../../utils/string';
import MaterialCheckboxInput from '../form/MaterialCheckboxInput';
import { RingsSpinner } from '../rings-spinner/RingsSpinner';

import MapPinIcon from '@cvent/carina/components/Icon/MapPin';

export interface VenueRecommendationsCellProps {
    position: number;
    onClick?: (position: number) => void;
    outreachPush?: boolean;

    id?: number;
    csn_id?: string;

    name: string;
    region: string;
    thumbnail_url: string;

    profile_url?: string;
    estimate_url?: string;
    estimate_total?: number;
    budget_capacity?: number;
    budget_min?: number;

    distance?: number;
    typeLabel?: string;
}

interface State {
    imageDownloadFailed: boolean;
}

export default class VenueRecommendationsCell extends React.Component<VenueRecommendationsCellProps, State> {
    readonly state: State = {
        imageDownloadFailed: false,
    };

    constructor(props: VenueRecommendationsCellProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onClickEstimate = this.onClickEstimate.bind(this);
    }

    tryGetProp(key: string, defaultVal: any) {
        return this.props[key] || defaultVal;
    }

    renderEstimateElement(value: number) {
        return <div className='VenueRecommendationsCell--estimate'>{`Your Spot Estimate: ${toUSD(value)}`}</div>;
    }

    renderBudgetElement(data?: VenueRecommendationsCellProps) {
        if (data === undefined) {
            return;
        }

        const { budget_capacity, budget_min } = data;

        if (!budget_capacity || !budget_min) {
            return;
        }

        let budgetStr;
        if (budget_min !== undefined) {
            budgetStr = `from ${toUSD(budget_min)}`;
            if (budget_capacity !== undefined) {
                budgetStr += ` for ${budget_capacity} guests`;
            }
        }

        return <div className='VenueRecommendationsCell--budget'>{budgetStr}</div>;
    }

    render() {
        const data = this.props;

        return (
            <div className='VenueRecommendationsCell'>
                <div onClick={this.onClick} className='VenueRecommendationsCell--clickable'>
                    {this.state.imageDownloadFailed ? (
                        <div className='VenueRecommendationsCell--image-placeholder-container'>
                            {data.typeLabel && (
                                <div className='VenueRecommendationsCell--image-type-label'>
                                    <span>{data.typeLabel}</span>
                                </div>
                            )}
                            <RingsSpinner animate={false} />
                        </div>
                    ) : (
                        <div className='VenueRecommendationsCell--image-container'>
                            {data.typeLabel && (
                                <div className='VenueRecommendationsCell--image-type-label'>
                                    <span>{data.typeLabel}</span>
                                </div>
                            )}
                            <img
                                src={this.tryGetProp('thumbnail_url', '')}
                                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                                    this.setState({ imageDownloadFailed: true });
                                }}
                                className='VenueRecommendationsCell--image'
                            />
                        </div>
                    )}
                </div>
                <div className='VenueRecommendationsCell--content'>
                    <div className='VenueRecommendationsCell--title'>
                        <div className='VenueRecommendationsCell--name'>{this.tryGetProp('name', '...')}</div>
                        <div className='VenueRecommendationsCell--region'>
                            <span className='is-truncate'>{this.tryGetProp('region', '...')}</span>
                            {!!this.props.distance && (
                                <div className='VenueRecommendationsCell--distance'>
                                    <MapPinIcon color='#69717A' size='s' />
                                    <div className='VenueRecommendationsCell--distanceText'>
                                        {this.props.distance.toFixed(1)} mi from event
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='VenueRecommendationsCell--underimage'>
                        {data !== undefined && data.estimate_total
                            ? this.renderEstimateElement(data.estimate_total)
                            : this.renderBudgetElement(data)}
                        {!this.props.outreachPush ? (
                            <>
                                <div
                                    className={cls(
                                        'VenueRecommendationsCell--link',
                                        'VenueRecommendationsCell--clickable',
                                        'nx-hide-for-small-only'
                                    )}
                                    onClick={this.onClickEstimate}
                                >
                                    <span className='VenueRecommendationsCell--link--venue-name'>{data.name}</span>
                                    <span className='icon-hb-arrowright-b' />
                                </div>
                                <div
                                    onClick={this.onClickEstimate}
                                    className={cls(
                                        'VenueRecommendationsCell--button-link',
                                        'VenueRecommendationsCell--clickable',
                                        'nx-show-for-small-only'
                                    )}
                                >
                                    <button
                                        className={`VenueRecommendationsCell--button
                                        nx-button nx-button--primary nx-button--x-small`}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className={`VenueRecommendationsCell--pushbox`}>
                                <Field
                                    name={`get_pricing_${this.props.position + 1}`}
                                    label='Get Pricing'
                                    component={MaterialCheckboxInput}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    private onClick() {
        if (!!this.props.profile_url) {
            window.open(this.props.profile_url, '_blank');
            !!this.props.onClick && this.props.onClick(this.props.position);
        }
    }

    private onClickEstimate() {
        if (!!this.props.estimate_url || !!this.props.profile_url) {
            window.open(this.props.estimate_url ? this.props.estimate_url : this.props.profile_url, '_blank');
            !!this.props.onClick && this.props.onClick(this.props.position);
        }
    }
}
